import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  AcCard,
  AcCardGrid,
  AcHelmet,
  AcSearchCard,
  AcSearchHeader,
  AcShimmer,
} from '@components'
import { DAEX_META } from '@constants'
import { useStore } from '@hooks/ac-use-store'
import { AcPagination } from 'src/components/features/ac-pagination/ac-pagination'

export const AcDataExplorerSearch = observer(() => {
  const { analytics } = useStore()
  const [searchParams] = useSearchParams()

  const renderSearchCards = useMemo(() => {
    if (!analytics.search_results?.length) return null
    const searchCards = []
    for (const card of analytics.search_results) {
      searchCards.push(
        <AcSearchCard
          key={card.slug}
          {...card}
        />
      )
    }
    return searchCards
  }, [analytics.search_results])

  useEffect(() => {
    const query = searchParams.get('q') || ''
    analytics.setSearchQuery(query)
  }, [searchParams])

  useEffect(() => {
    analytics.getSearchResults()
  }, [analytics.search_query, analytics.activeFilters])

  return (
    <div>
      <AcHelmet
        title={DAEX_META.SEARCH.TITLE}
        description={DAEX_META.SEARCH.DESCRIPTION}
      />
      <AcCardGrid pt={4}>
        <AcSearchHeader />
        {!analytics.loading ? (
          renderSearchCards
        ) : (
          <AcCard>
            <AcShimmer />
          </AcCard>
        )}
        {analytics.has_pagination && (
          <AcPagination
            store={analytics}
            pagination={analytics.pagination}
          />
        )}
      </AcCardGrid>
    </div>
  )
})
