import { BREAKPOINTS, ICONS, KEYS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { useStore } from '@hooks/ac-use-store'
import {
  IAnalyticsCompanyDirectorsItem,
  IAnalyticsDirectorsSubset,
} from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcDataItem } from '../ac-data-item/ac-data-item'
import { AcCardWithTitle } from '../index.features.components'

interface IAcCompanyDetailPeopleProps {
  subset?: IAnalyticsDirectorsSubset
  title: string
  subTitle?: string
  seats: string
}

export const AcCompanyDetailDirectors = observer(
  ({
    subset = KEYS.DIRECTORS,
    title,
    subTitle,
    seats,
  }: IAcCompanyDetailPeopleProps) => {
    const { analytics } = useStore()
    const isDesktop = AcUseBreakpoint(BREAKPOINTS.SM)
    const currentData = useMemo(
      () => analytics.directors[subset],
      [subset, analytics.directors]
    )

    const getLabel = (type: IAnalyticsCompanyDirectorsItem['type']) => {
      switch (type) {
        case 'seats':
          return seats
        case 'females':
          return 'Aantal vrouwen'
        case 'males':
          return 'Aantal mannen'
        case 'unfilled_vacancies':
          return 'Aantal niet-ingevulde vacatures'
      }
    }

    const getIcon = (type: IAnalyticsCompanyDirectorsItem['type']) => {
      switch (type) {
        case 'seats':
          return ICONS.GROUP
        case 'males':
          return ICONS.MALES
        case 'females':
          return ICONS.FEMALES
        case 'unfilled_vacancies':
          return ICONS.SUITCASE
      }
    }

    const currentColor = useMemo(() => {
      switch (subset) {
        case 'supervisory':
          return '--ac-color-secondary-green'
        default:
          return '--ac-color-secondary-orange'
      }
    }, [subset])

    return (
      <AcCardWithTitle
        title={title}
        subTitle={subTitle}>
        {currentData &&
          currentData.map((singleItem: IAnalyticsCompanyDirectorsItem) => {
            return (
              <AcDataItem
                key={singleItem.type}
                title={`${singleItem.value}`}
                label={getLabel(singleItem.type)}
                direction={isDesktop ? 'horizontal' : 'vertical'}
                iconColor={currentColor}
                icon={getIcon(singleItem.type)}
              />
            )
          })}
      </AcCardWithTitle>
    )
  }
)
