import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart, GaugeChart, LineChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { useMemo } from 'react'

import styles from './ac-chart-bar-target.module.scss'

import { DAEX_COLORS_ACCENT, LABELS } from '@constants'
import { AcChartBarTargetMap } from './ac-chart-bar-target-map'
import { AcPercentageString } from '@helpers/ac-percentage-string.helpers'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GaugeChart,
  BarChart,
  LineChart,
  MarkLineComponent,
  CanvasRenderer,
])

interface IAcChartBarTarget {
  seriesData: any
  department: 'DIRECTORS' | 'SUPERVISORY' | 'SUBTOP'
}

export const AcChartBarTarget = ({
  seriesData,
  department,
}: IAcChartBarTarget) => {
  const type = LABELS[department]

  const renderChart = useMemo(() => {
    const { series } = AcChartBarTargetMap({ seriesData })

    const opts = {
      animationDelayUpdate: function (idx: number) {
        return idx * 5
      },
      color: DAEX_COLORS_ACCENT,
      legend: {
        show: false,
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
      tooltip: {
        trigger: 'item',
        formatter: ({ value, seriesName }: any) => {
          value = parseFloat(value)
          return `<div>${LABELS.CURRENT_YEAR} <br/> 
          Bedrijven met een streefcijfer van ${seriesName}%, ${type}: <strong>${AcPercentageString(
            value
          )}%</strong>
          </div>`
        },
        textStyle: {
          color: '#241D6D',
        },
        confine: false,
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        type: 'category',
        data: [''],
        show: false,
      },
      series,
    }

    return (
      <div className={styles['ac-chart-bar-target-wrapper']}>
        <ReactEChartsCore
          className={styles['ac-chart-bar-target']}
          style={{ height: '42px', width: '100%' }}
          echarts={echarts}
          option={opts}
        />
      </div>
    )
  }, [seriesData])

  return renderChart
}
