import ReactEChartsCore from 'echarts-for-react/lib/core'
import { PieChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LABELS } from '@constants'
import { AcChartStackedCircleFemalesMap } from './ac-chart-stacked-circle-females-map'
import type { IEChartTooltipParams } from '@typings'
import { AcPercentageString } from '@helpers/ac-percentage-string.helpers'

import styles from './ac-chart-stacked-circle-females.module.scss'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
])

interface IAcChartStackedCircleFemales {
  seriesData: any
}

export const AcChartStackedCircleFemales = ({
  seriesData,
}: IAcChartStackedCircleFemales) => {
  const { series } = AcChartStackedCircleFemalesMap({ seriesData })

  const opts = {
    animationDelayUpdate: function (idx: number) {
      return idx * 5
    },
    tooltip: {
      trigger: 'item',
      formatter: ({ value, seriesName }: IEChartTooltipParams) => {
        return `${AcPercentageString(value)}% in ${
          LABELS.CURRENT_YEAR
        }<br/>Gemiddeld percentage vrouwen in ${seriesName}`
      },
      textStyle: {
        fontFamily: 'Dax',
        color: '#241D6D',
      },
      confine: true,
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    series,
  }
  return (
    <div
      className={styles['ac-chart-wrapper']}
      style={
        {
          '--current-year-pie-chart': `'${LABELS.CURRENT_YEAR}'`,
        } as React.CSSProperties
      }>
      <ReactEChartsCore
        className={styles['ac-chart-stacked-circle-females']}
        echarts={echarts}
        option={opts}
        style={{ width: '100%', aspectRatio: '1', height: 'unset' }}
      />
    </div>
  )
}
