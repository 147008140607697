import { forwardRef } from 'react'
import { AcInputBase } from '../ac-input-base/ac-input-base'
import { AcTypography } from '../ac-typography/ac-typography'

import styles from './ac-text-input.module.scss'

export interface IAcTextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  id: string
  className?: string
  type?: 'text' | 'email' | 'password' | 'url' | 'number' | 'tel' | 'search'
  dense?: boolean
  loading?: boolean
  hint?: string
  error?: boolean | string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
}

export interface IAcTextareaInputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string
  id: string
  className?: string
  type?: 'textarea'
}
const AcTextInput = forwardRef<
  HTMLElement,
  IAcTextInputProps | IAcTextareaInputProps
>(({ type = 'text', ...props }, ref) => {
  if (type === 'textarea') {
    const { name, className, ...inputProps } = props as IAcTextareaInputProps
    return (
      <div className={className}>
        <textarea
          ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
          {...inputProps}
          name={name}
        />
      </div>
    )
  } else {
    const {
      name,
      className,
      dense,
      loading,
      hint,
      error,
      startAdornment,
      endAdornment,
      ...inputProps
    } = props as IAcTextInputProps

    return (
      <AcInputBase
        className={className}
        error={error}
        hint={hint}
        disabled={inputProps.disabled}
        dense={dense}
        loading={loading}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        withoutBorders={inputProps.readOnly}>
        {inputProps.readOnly ? (
          <AcTypography
            weight="semibold"
            size="sm"
            element="span">
            {inputProps.value}
          </AcTypography>
        ) : (
          <input
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            aria-describedby={inputProps.id + '_hint'}
            {...inputProps}
            type={type}
            name={name}
            disabled={inputProps.disabled || loading}
            className={styles['ac-text-input']}
          />
        )}
      </AcInputBase>
    )
  }
})
AcTextInput.displayName = 'AcTextInput'

export { AcTextInput }
