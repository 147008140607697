import { Outlet, useNavigate } from 'react-router-dom'

import { AcButtonBack, AcLayoutWrapper } from '@components'

import styles from './ac-data-explorer-about.module.scss'
import { ROUTE_PATHS } from '@constants'

export const AcDataExplorerAboutLayout = () => {
  const navigate = useNavigate()

  return (
    <AcLayoutWrapper background="white">
      <div className={styles['ac-data-explorer-about--container']}>
        <AcButtonBack
          className={styles['ac-data-explorer-about-back-button']}
          onClick={() => navigate(ROUTE_PATHS.DAEX)}
        />
        <Outlet />
      </div>
    </AcLayoutWrapper>
  )
}
