import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'

import { LABELS, ROUTE_PATHS } from '@constants'
import { AcButtonBack, AcSearchInput, AcTitle } from '@components'
import { useStore } from '@hooks/ac-use-store'

import styles from './ac-search-header.module.scss'
import { withDebounce } from '@helpers/with-debounce'

export const AcSearchHeader = observer(() => {
  const { analytics } = useStore()
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate({ pathname: ROUTE_PATHS.ANALYTICS })
  }

  const renderSearchStatus = useMemo(() => {
    if (analytics.search_query.length < 2) return LABELS.MINIMAL_2_CHARACTERS
    if (analytics.loading || analytics.search_results === null)
      return LABELS.LOADING

    const resultPlural =
      analytics.search_results.length === 1 ? LABELS.RESULT : LABELS.RESULTS
    return `${analytics.pagination.total} ${resultPlural}`
  }, [analytics.search_results, analytics.loading])

  const renderTitle = useMemo(() => {
    return `${LABELS.YOU_WERE_SEARCHING_FOR}: ${analytics.search_query}`
  }, [analytics.search_results])

  const handleSearchTerm = withDebounce(
    (term: string) => analytics.setSearchQuery(term),
    600
  )

  return (
    <div className={styles['ac-search-header']}>
      <AcButtonBack
        label={LABELS.BACK_TO_DATA_EXPLORER}
        onClick={handleGoBack}
      />
      <AcTitle>{renderTitle}</AcTitle>
      <AcSearchInput
        initialValue={analytics.search_query}
        onChange={e => handleSearchTerm(e.target.value)}
      />

      <AcTitle level={4}>{renderSearchStatus}</AcTitle>
    </div>
  )
})
