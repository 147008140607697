import { ICONS, TOOLTIPS, LABELS } from '@constants'
import { useStore } from '@hooks/ac-use-store'
import { observer } from 'mobx-react-lite'
import { AcDataItem } from '../ac-data-item/ac-data-item'
import { AcCardWithTitle } from '../index.features.components'
import { IAnalyticsCompanySubtopFlowItem } from '@typings'

interface IAcCompanyDetailSubtopFlowProps {
  title?: string
  subTitle?: string
}

export const AcCompanyDetailSubtopFlow = observer(
  ({ title, subTitle }: IAcCompanyDetailSubtopFlowProps) => {
    const { analytics } = useStore()

    const getLabel = ({ value, total }: IAnalyticsCompanySubtopFlowItem) => {
      if ((!value || !total) && value !== 0) return 'Geen data beschikbaar'
      if (value === 1) return `${value} vrouw van totaal ${total} werknemers`
      return `${value} vrouwen van totaal ${total} werknemers`
    }

    const getSubtitle = ({ type }: IAnalyticsCompanySubtopFlowItem) => {
      if (type === 'influx') return `vrouwen ${LABELS.INFLUX.toLowerCase()}`
      return `vrouwen ${LABELS.OUTFLOW.toLowerCase()}`
    }

    return (
      <AcCardWithTitle
        title={title}
        subTitle={subTitle}>
        {analytics.subtop &&
          analytics.subtop.map(singleSubtop => (
            <AcDataItem
              key={singleSubtop.type}
              title={`${singleSubtop.percentage}%`}
              label={getLabel(singleSubtop)}
              subtitle={getSubtitle(singleSubtop)}
              icon={
                singleSubtop.type === 'influx' ? ICONS.INFLUX : ICONS.OUTFLOW
              }
              iconColor="--ac-color-secondary-lightblue2"
              direction="horizontal"
              toolTip={[
                ...TOOLTIPS.DAEX_INDEX.TOP_SUBTOP.SUBTOP[
                  singleSubtop.type.toUpperCase() as 'INFLUX' | 'OUTFLOW'
                ],
              ]}
              toolTipPlacement="label"
            />
          ))}
      </AcCardWithTitle>
    )
  }
)
