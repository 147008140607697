import { observer } from 'mobx-react-lite'

import { LABELS } from '@constants'
import {
  AcChartStackedCircleFemales,
  AcSearchInput,
  AcTitle,
  AcTypography,
} from '@components'
import { useStore } from '@hooks/ac-use-store'

import styles from './ac-hero.module.scss'

export const AcHero = observer(() => {
  const { analytics } = useStore()

  return (
    <section className={styles['ac-hero']}>
      <div className={styles['ac-hero-wrapper']}>
        <div>
          <AcTitle level={1}>{LABELS.HERO_TITLE}</AcTitle>
          <AcTypography>{LABELS.HERO_TEXT}</AcTypography>
          <AcSearchInput
            initialValue={analytics.search_query}
            onChange={e => analytics.setSearchQuery(e.target.value)}
          />
        </div>
        <div className={styles['ac-hero-chart-wrapper']}>
          <AcChartStackedCircleFemales seriesData={analytics.index_average} />
        </div>
      </div>
    </section>
  )
})
