import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import {
  IAnalyticsCompany,
  IAnalyticsCompanyFemalesYear,
  IAnalyticsIndexAppointments,
  IAnalyticsIndexAverage,
  IAnalyticsIndexFemales,
  IAnalyticsIndexFilters,
  IAnalyticsIndexFlow,
  IAnalyticsIndexMaidenless,
  IAnalyticsIndexTarget,
  IAnalyticsPagination,
  IAnalyticsSearchResult,
} from '@typings'
import { Store } from './index.stores'

import {
  IAnalyticsCompanyDirectors,
  IAnalyticsCompanyEmployees,
  IAnalyticsCompanyPlan,
  IAnalyticsIndexPva,
  IAnalyticsCompanySubtopFlow,
  IAnalyticsDefinition,
  IAppointmentData,
} from '@typings'

let controllerCompany: Nullable<AbortController>
let controllerFemales: Nullable<AbortController>
let controllerSearch: Nullable<AbortController>

const defaultValues = {
  companies: [] as IAnalyticsCompany[],
  company: {} as IAnalyticsCompany,
  females: [] as IAnalyticsCompanyFemalesYear[],
  appointments: {} as IAppointmentData,
  directors: {} as IAnalyticsCompanyDirectors,
  employees: {} as IAnalyticsCompanyEmployees,
  definition: {} as IAnalyticsDefinition,
  subtop: [] as IAnalyticsCompanySubtopFlow,
  plan: {} as IAnalyticsCompanyPlan,
  pva: {} as IAnalyticsIndexPva,
} as { [key: string]: any }

export class AnalyticsStore {
  _store: Store
  loading: boolean
  company: IAnalyticsCompany
  companies: IAnalyticsCompany[]
  females: IAnalyticsCompanyFemalesYear[]

  activeFilters: { [key: string]: any }
  search_query: string
  search_results: IAnalyticsSearchResult[]
  pagination: IAnalyticsPagination
  appointments: IAppointmentData
  definition: IAnalyticsDefinition
  directors: IAnalyticsCompanyDirectors
  employees: IAnalyticsCompanyEmployees
  subtop: IAnalyticsCompanySubtopFlow
  plan: IAnalyticsCompanyPlan

  index_filter_query: {
    sector: string
    size: string
  }
  index_filters: IAnalyticsIndexFilters
  index_females: IAnalyticsIndexFemales
  index_average: IAnalyticsIndexAverage
  index_target: IAnalyticsIndexTarget
  index_appointments: IAnalyticsIndexAppointments
  index_flow: IAnalyticsIndexFlow
  index_maidenless: IAnalyticsIndexMaidenless
  index_pva: IAnalyticsIndexPva

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = true
    this.company = {} as IAnalyticsCompany
    this.females = [] as IAnalyticsCompanyFemalesYear[]
    this.activeFilters = {}
    this.search_query = ''
    this.search_results = []

    this.companies = defaultValues.companies
    this.company = defaultValues.company
    this.females = defaultValues.females
    this.appointments = defaultValues.appointments
    this.directors = defaultValues.directors
    this.employees = defaultValues.employees
    this.definition = defaultValues.definition
    this.subtop = defaultValues.subtop
    this.plan = defaultValues.plan

    this.index_filter_query = {
      sector: '',
      size: '',
    }
    this.index_filters = {} as IAnalyticsIndexFilters
    this.index_females = [] as IAnalyticsIndexFemales
    this.index_average = [] as IAnalyticsIndexAverage
    this.index_target = [] as IAnalyticsIndexTarget
    this.index_appointments = [] as IAnalyticsIndexAppointments
    this.index_flow = [] as IAnalyticsIndexFlow
    this.index_maidenless = [] as IAnalyticsIndexMaidenless
    this.index_pva = [] as IAnalyticsIndexPva
    this.plan = defaultValues.plan
    this.company = {} as IAnalyticsCompany
    this.females = [] as IAnalyticsCompanyFemalesYear[]
    this.search_query = ''
    this.pagination = {
      current_page: 1,
      last_page: 2,
      per_page: 15,
      total: 23,
    } as IAnalyticsPagination
  }

  @computed
  get has_pagination(): boolean {
    return (
      this.search_results.length > 0 &&
      this.pagination.total > this.pagination.per_page
    )
  }

  @computed
  get search_query_is_empty() {
    return this.search_query?.length < 3
  }

  @computed
  get current_active_tab(): any {
    return this.company.slug
  }

  @computed
  get current_company_companies(): IAnalyticsCompany[] {
    return this.companies ?? []
  }

  @computed
  get current_index_filter_query() {
    return this.index_filter_query
  }

  @computed
  get is_index_filtered() {
    return !!(this.index_filter_query.sector || this.index_filter_query.size)
  }

  @computed
  get is_size_filtered() {
    return !!this.index_filter_query.size
  }

  @action
  getBySlug = async (slug: string, fromTabClick: boolean) => {
    let company

    this._store.set(STORE_KEYS.ANALYTICS, KEYS.LOADING, true)

    if (!fromTabClick) {
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.COMPANIES, [])
    }

    if (controllerCompany) controllerCompany.abort()
    controllerCompany = new AbortController()

    try {
      company = await this._store.api.analytics.get_single_by_slug(slug)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.COMPANY, company)
    } catch (e) {
      return Promise.reject(e)
    }

    try {
      if (!fromTabClick || (fromTabClick && this.companies.length === 0)) {
        await this.getCompanies(slug)
      }
      if (company.status === 'available') {
        this.getFemales(slug)
        this.getDetailDirectors(slug)
        this.getDetailEmployees(slug)
        this.getDetailAppointments(slug)
        this.getDetailDefinitions(slug)
        this.getDetailFlow(slug)
        this.getDetailPlan()
      } else {
        this._store.set(
          STORE_KEYS.ANALYTICS,
          KEYS.FEMALES,
          defaultValues.females
        )
        this._store.set(
          STORE_KEYS.ANALYTICS,
          KEYS.DIRECTORS,
          defaultValues.directors
        )
        this._store.set(
          STORE_KEYS.ANALYTICS,
          KEYS.EMPLOYEES,
          defaultValues.employees
        )
        this._store.set(
          STORE_KEYS.ANALYTICS,
          KEYS.APPOINTMENTS,
          defaultValues.appointments
        )
        this._store.set(
          STORE_KEYS.ANALYTICS,
          KEYS.DEFINITION,
          defaultValues.definition
        )
        this._store.set(STORE_KEYS.ANALYTICS, KEYS.SUBTOP, defaultValues.subtop)
        this._store.set(STORE_KEYS.ANALYTICS, KEYS.PLAN, defaultValues.plan)
      }

      return
    } catch (e) {
      if (e) console.error(e)
    } finally {
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.LOADING, false)
    }
  }

  @action
  getCompanies = async (slug: string) => {
    try {
      const res = await this._store.api.analytics.get_companies(slug)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.COMPANIES, res)
      return Promise.resolve(res)
    } catch (e) {
      console.log('e: ', e)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.COMPANIES, [])
      return Promise.reject(e)
    }
  }
  @action
  getFemales = async (slug: string) => {
    if (controllerFemales) controllerFemales.abort()
    controllerFemales = new AbortController()

    try {
      const res = await this._store.api.analytics.get_females(slug)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.FEMALES, res)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getDetailEmployees = async (slug: string) => {
    try {
      const res = await this._store.api.analytics.get_detail_employees(slug)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.EMPLOYEES, res)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getDetailDirectors = async (slug: string) => {
    try {
      const directors = await this._store.api.analytics.get_detail_directors(
        slug
      )
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.DIRECTORS, directors)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getDetailAppointments = async (slug: string) => {
    try {
      const appointments =
        await this._store.api.analytics.get_detail_appointments(slug)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.APPOINTMENTS, appointments)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getDetailDefinitions = async (slug: string) => {
    try {
      const definition = await this._store.api.analytics.get_detail_definition(
        slug
      )
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.DEFINITION, definition)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getDetailPlan = async () => {
    try {
      const plan = await this._store.api.analytics.get_detail_plan(
        this.company.slug
      )
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.PLAN, plan)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getDetailFlow = async (slug: string) => {
    try {
      const flow = await this._store.api.analytics.get_detail_subtop(slug)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.SUBTOP, flow)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  resetCompanyDetail = () => {
    const keys = Object.keys(defaultValues)
    for (const key of keys) {
      this._store.set(
        STORE_KEYS.ANALYTICS,
        key as keyof AnalyticsStore,
        defaultValues[key]
      )
    }
  }

  @action
  handleFilterChange = (key: string, value: any) => {
    if (
      key !== KEYS.PAGE &&
      !!this.activeFilters.page &&
      this.activeFilters[key] !== value
    ) {
      this.activeFilters = {
        ...this.activeFilters,
        page: 1,
      }
    }
    const newFilter = { ...this.activeFilters, [key]: value }
    this._store.set(STORE_KEYS.ANALYTICS, KEYS.ACTIVE_FILTERS, newFilter)
  }

  @action
  handlePagination = (direction: 'previous' | 'next' | 'reset' | number) => {
    if (this.pagination) {
      if (direction === KEYS.PREVIOUS && this.pagination.current_page > 1)
        return this.handleFilterChange(
          KEYS.PAGE,
          this.pagination.current_page - 1
        )
      if (
        direction === KEYS.NEXT &&
        this.pagination?.last_page > this.pagination?.current_page
      ) {
        return this.handleFilterChange(
          KEYS.PAGE,
          this.pagination.current_page + 1
        )
      }
      if (direction === KEYS.RESET) return this.handleFilterChange(KEYS.PAGE, 1)

      return this.handleFilterChange(KEYS.PAGE, direction)
    }
  }

  @action
  setSearchQuery = (query: string) => {
    this._store.set(STORE_KEYS.ANALYTICS, KEYS.SEARCH_QUERY, query)
    this.handleFilterChange(KEYS.Q, query)
  }

  @action
  resetSearch = () => {
    this._store.set(
      STORE_KEYS.ANALYTICS,
      KEYS.PAGINATION,
      {} as IAnalyticsPagination
    )
    this._store.set(STORE_KEYS.ANALYTICS, KEYS.SEARCH_QUERY, '')
    this._store.set(STORE_KEYS.ANALYTICS, KEYS.ACTIVE_FILTERS, {})
  }

  @action
  getSearchResults = async () => {
    this._store.set(STORE_KEYS.ANALYTICS, KEYS.LOADING, true)
    this._store.set(STORE_KEYS.ANALYTICS, KEYS.SEARCH_RESULTS, [])
    if (controllerSearch) controllerSearch.abort()
    controllerSearch = new AbortController()
    const params = { ...this.activeFilters }

    try {
      const { data, meta } = await this._store.api.analytics.get_search_results(
        params,
        {
          signal: controllerSearch.signal,
        }
      )
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.SEARCH_RESULTS, data)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.PAGINATION, meta)
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.LOADING, false)
      return data
    } catch (e) {
      if (e) {
        console.error(e)
        this._store.set(STORE_KEYS.ANALYTICS, KEYS.SEARCH_RESULTS, [])
        this._store.set(STORE_KEYS.ANALYTICS, KEYS.LOADING, false)
      }
    }
  }

  @action
  setFilterQuery = ({ sector, size }: { sector?: string; size?: string }) => {
    const query = { ...this.index_filter_query }

    if (sector !== undefined) query.sector = sector || ''
    if (size !== undefined) query.size = size || ''

    this._store.set(STORE_KEYS.ANALYTICS, 'index_filter_query', query)
  }

  @action
  getIndex = async () => {
    try {
      this.getIndexFemales()
      this.getIndexAverage()
      this.getIndexTarget()
      this.getIndexAppointments()
      this.getIndexFlow()
      this.getIndexMaidenless()
      this.getIndexPva()

      return
    } catch (e) {
      if (e) console.error(e)
    } finally {
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.LOADING, false)
    }
  }

  @action
  getIndexFilters = async () => {
    try {
      const filters = await this._store.api.analytics.get_index_filters()
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.INDEX_FILTERS, filters)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getIndexFemales = async () => {
    if (this.is_index_filtered) return

    try {
      const females = await this._store.api.analytics.get_index_females()
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.INDEX_FEMALES, females)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getIndexAverage = async () => {
    try {
      const average = await this._store.api.analytics.get_index_average({
        params: this.index_filter_query,
      })
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.INDEX_AVERAGE, average)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getIndexTarget = async () => {
    try {
      const target = await this._store.api.analytics.get_index_target({
        params: this.index_filter_query,
      })
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.INDEX_TARGET, target)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getIndexAppointments = async () => {
    try {
      const appointments =
        await this._store.api.analytics.get_index_appointments({
          params: this.index_filter_query,
        })
      this._store.set(
        STORE_KEYS.ANALYTICS,
        KEYS.INDEX_APPOINTMENTS,
        appointments
      )
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getIndexFlow = async () => {
    try {
      const flow = await this._store.api.analytics.get_index_flow({
        params: this.index_filter_query,
      })
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.INDEX_FLOW, flow)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  @action
  getIndexMaidenless = async () => {
    try {
      const maidenless = await this._store.api.analytics.get_index_maidenless({
        params: this.index_filter_query,
      })
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.INDEX_MAIDENLESS, maidenless)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }

  getIndexPva = async () => {
    try {
      const plan = await this._store.api.analytics.get_index_pva({
        params: this.index_filter_query,
      })
      this._store.set(STORE_KEYS.ANALYTICS, KEYS.INDEX_PVA, plan)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    }
  }
}
