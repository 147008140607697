import { AcCompanyTabs, AcLayoutWrapper } from '@components'
import { Outlet } from 'react-router-dom'

export const AcDaexCompanyDetailLayout = () => {
  return (
    <AcLayoutWrapper aboveMainChildren={<AcCompanyTabs />}>
      <Outlet />
    </AcLayoutWrapper>
  )
}
