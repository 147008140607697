import { DAEX_404 } from './daex-404.constants'
import { LABELS } from './labels.constants'

export const DAEX_META = {
  INDEX: {
    TITLE: 'Dataverkenner',
    DESCRIPTION: LABELS.HERO_TEXT,
  },
  COMPANY: {
    TITLE: '{{company}} in de dataverkenner',
    DESCRIPTION: '{{company}} in de dataverkenner',
  },
  ABOUT: {
    TITLE: 'Over de dataverkenner',
    DESCRIPTION: LABELS.HERO_TEXT,
  },
  SEARCH: {
    TITLE: 'Zoeken in de dataverkenner',
    DESCRIPTION: 'Zoeken in de dataverkenner',
  },
  NOT_FOUND: {
    TITLE: DAEX_404.TITLE.MAIN,
    DESCRIPTION: DAEX_404.CONTENT,
  },
}
