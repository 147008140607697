import clsx from 'clsx'
import { forwardRef, useMemo } from 'react'
import styles from './ac-tab-label.module.scss'
import { IAcSingleTab } from '../ac-tabs-header'

interface IAcTabLabel {
  active?: boolean
  error?: boolean
  children: React.ReactNode
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  slug: IAcSingleTab['slug']
  loading?: boolean
}

const AcTabLabel = forwardRef<HTMLButtonElement, IAcTabLabel>(
  (
    { children, onClick, slug, error = false, active = false, loading = false },
    ref
  ) => {
    const getTabLabelClassNames = useMemo(
      () =>
        clsx([
          styles['ac-tab-label'],
          error && styles['ac-tab-label--error'],
          active && styles['ac-tab-label--active'],
        ]),
      [error, active]
    )
    return (
      <button
        ref={ref}
        data-tab={slug}
        type="button"
        onClick={onClick}
        className={getTabLabelClassNames}
        disabled={loading}
        role="tab">
        {children}
      </button>
    )
  }
)

AcTabLabel.displayName = 'AcTabLabel'

export { AcTabLabel }
