import { useEffect, useState } from 'react'

export const AcUseVisible = (element?: Nullable<Element>) => {
  const [isVisible, setVisibility] = useState<boolean>(false)

  useEffect(() => {
    if (!element) return

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (isVisible !== entry.intersectionRatio > 0)
            setVisibility(!isVisible)
        })
      },
      { root: document.documentElement }
    )
    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [element])

  return isVisible
}
