export const COLORS = {
  background: {
    default: '#F5F5F5',
    primary: '#D3E7F4',
    main: '#F5F5F5',
    contrastText: '#37493B',
  },
  primary: {
    main: '#241D6D',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#D3E7F4',
    contrastText: '#241D6D',
  },
  tertiary: {
    main: 'rgba(84, 180, 228, 1)',
    contrastText: '#FFFFFF',
  },
  lightBlue: {
    main: 'rgb(84, 180, 228)',
    contrastText: '#FFFFFF',
  },
  buttonIcon: {
    main: '#FFFFFF',
  },
  navigation: {
    main: '#006864',
    mainTransparent: 'rgba(0, 104, 100, 0.5)',
    active: '#4CB6B6',
    contrastText: '#fff',
  },
  text: {
    main: '#37474F',
    primary: '#37474F',
  },
  header: {
    main: '#EEE',
  },
  white: {
    main: '#ffffff',
    contrastText: '#37474F',
  },
  black: {
    main: '#000',
    contrastText: '#ffffff',
  },
  gray: {
    main: 'rgba(185, 185, 186, 1)',
    contrastText: '#37474F',
    disabled: 'rgba(185, 185, 186, 1)',
  },
  success: {
    main: '#999E19',
    light: 'rgba(217, 219, 162)',
    contrastText: '#8D932C',
  },
  error: {
    // main: '#FF0000',
    main: '#F5E0DE',
    contrastText: '#C43C2C',
  },
  warning: {
    main: '#D89427',
    light: '#FBD79E',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#D3E7F4',
    contrastText: '#241D6D',
  },
  disabled: {
    main: 'rgba(238, 238, 238, 1)',
    contrastText: 'rgba(185, 185, 186, 1)',
  },
}
export const DAEX_COLOR_PRIMARY = '#241D6D'
export const DAEX_COLORS_SECONDARY_MAP = {
  FUCHIA: '#AE3F81',
  ORANGE: '#E28A34',
  GREEN: '#999E19',
  LIGHT_BLUE: '#54B4E4',
}
export const DAEX_COLORS_ACCENT_MAP = {
  BLUE_100: '#A7A5C5',
  BLUE_200: '#7C77A7',
  GREEN_100: '#C4E2AA',
  GREEN_200: '#89C758',
  GREEN_300: '#68AD31',
}

export const DAEX_COLORS_SECONDARY = [
  DAEX_COLORS_SECONDARY_MAP.FUCHIA,
  DAEX_COLORS_SECONDARY_MAP.ORANGE,
  DAEX_COLORS_SECONDARY_MAP.GREEN,
  DAEX_COLORS_SECONDARY_MAP.LIGHT_BLUE,
]
export const DAEX_COLORS_ACCENT = [
  DAEX_COLOR_PRIMARY,
  DAEX_COLORS_ACCENT_MAP.BLUE_200,
  DAEX_COLORS_ACCENT_MAP.BLUE_100,
  DAEX_COLORS_ACCENT_MAP.GREEN_100,
  DAEX_COLORS_ACCENT_MAP.GREEN_200,
  DAEX_COLORS_ACCENT_MAP.GREEN_300,
]

export const DAEX_COLORS_SECONDARY_GRADIENT = DAEX_COLORS_SECONDARY.map(
  hex => ({
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: hex,
      },
      {
        offset: 1,
        color: '#FFFFFF',
      },
    ],
  })
)

export const DAEX_COLORS_SECONDARY_GRADIENT_ALT = DAEX_COLORS_SECONDARY.map(
  hex => ({
    type: 'linear',
    x: 1,
    y: 0,
    x2: 0,
    y2: 0,
    colorStops: [
      {
        offset: 0,
        color: hex,
      },
      {
        offset: 1,
        color: '#FFFFFF',
      },
    ],
  })
)
