export const KEYS = {
  APPOINTMENT: 'appointment',
  APPOINTMENTS: 'appointments',
  APPROACH: 'approach',
  ACTIVE_FILTERS: 'activeFilters',
  COMPANIES: 'companies',
  COMPANY: 'company',
  DEFINITION: 'definition',
  DIRECTORS: 'directors',
  EMPLOYEES: 'employees',
  FEMALES: 'females',
  FLOW: 'flow',
  NEXT: 'next',
  PLAN: 'plan',
  LOADING: 'loading',
  ORGANISATION: 'organisation',
  REAPPOINTMENT: 'reappointment',
  RESIGN: 'resign',
  SUPERVISORY: 'supervisory',
  SUBTOP: 'subtop',
  SEARCH: 'search',
  SEARCH_QUERY: 'search_query',
  SEARCH_RESULTS: 'search_results',
  INDEX_FILTERS: 'index_filters',
  INDEX_FEMALES: 'index_females',
  INDEX_TARGET: 'index_target',
  INDEX_AVERAGE: 'index_average',
  INDEX_APPOINTMENTS: 'index_appointments',
  INDEX_FLOW: 'index_flow',
  INDEX_MAIDENLESS: 'index_maidenless',
  INDEX_PVA: 'index_pva',
  INDEX_FILTER_QUERY: 'index_filter_query',
  PAGINATION: 'pagination',
  PREVIOUS: 'previous',
  PAGE: 'page',
  RESET: 'reset',
  SIZES: 'sizes',
  SECTORS: 'sectors',
  INFLUX: 'influx',
  OUTFLOW: 'outflow',
  Q: 'q',
  NO_DATA: '0.0',
} as const

export const STORE_KEYS = {
  ANALYTICS: 'analytics',
} as const
