import { useMemo } from 'react'

import { ICONS, LABELS } from '@constants'
import { AcIntlFloat } from '@helpers/ac-intl-number.helper'
import { AcDataItem } from '../ac-data-item/ac-data-item'
import { AcCardWithTitle } from '../index.features.components'

interface IAcCardPlan {
  title: string
  subtitle: string
  seriesData: any
}

const getTitle = (percentage: number) => {
  const percentageFloat = AcIntlFloat(percentage, 1)
  return percentageFloat ? percentageFloat + '%' : '-'
}

const getToolTip = (i: number) => {
  if (i === 0)
    return 'Onder een formeel plan van aanpak wordt een zichzelf staand beleid verstaan of beleid dat is opgenomen in een ander beleidsplan. Het beleid moet zijn beschreven en goedgekeurd door het bestuur'
  if (i === 1)
    return 'Percentage bedrijven dat het plan van aanpak heeft gedeeld met de organisatie en/of stakeholders (in procenten van het totaal aantal bedrijven met een formeel plan)'
}

export const AcCardPva = ({ title, subtitle, seriesData }: IAcCardPlan) => {
  const renderDataItems = useMemo(() => {
    return seriesData.map((singleItem: any, i: number) => {
      return (
        <AcDataItem
          key={i}
          title={getTitle(singleItem.percentage)}
          subtitle={`${singleItem?.value?.toLocaleString('nl-NL')} bedrijven`}
          label={
            singleItem.type === 'pva-available'
              ? LABELS.HAS_PVA
              : LABELS.HAS_SHARED_PVA
          }
          direction="horizontal"
          toolTip={getToolTip(i)}
        />
      )
    })
  }, [seriesData])

  return (
    <AcCardWithTitle
      title={title}
      subTitle={subtitle}
      icon={ICONS.PVA}>
      {renderDataItems}
    </AcCardWithTitle>
  )
}
