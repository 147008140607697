import { ErrorBoundary, Provider } from '@rollbar/react'
import React from 'react'
import { useRoutes } from 'react-router-dom'
import Rollbar from 'rollbar'
import { createRoutes } from './router/routes'
// Styles

// Components
import ScrollToTop from './utils/components/ScrollToTop'
import { CssBaseline } from '@mui/material'

const ROLLBAR_KEY = process.env.REACT_APP_ROLLBAR_KEY
const ROLLBAR_ENVIRONMENT = process.env.REACT_APP_ROLLBAR_ENVIRONMENT

const RollbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  if (!(ROLLBAR_KEY && ROLLBAR_ENVIRONMENT)) return <>{children}</>

  const rollbarConfig = new Rollbar({
    accessToken: ROLLBAR_KEY,
    environment: ROLLBAR_ENVIRONMENT,
  })
  return (
    <Provider instance={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  )
}

const App: React.FC = () => {
  const routes = useRoutes(createRoutes())

  return (
    <RollbarProvider>
      <CssBaseline />
      <ScrollToTop />
      {routes}
    </RollbarProvider>
  )
}

export default App
