import { KEYS, LABELS } from '@constants'
import {
  AcCompanyDetailAppointment,
  AcCompanyDetailDirectors,
  AcCompanyDetailWomenChartComponent,
  AcNoReporting,
} from '@components'

export interface IAcDataExplorerCompanyDirectorsTab {
  hasReported: boolean
  reasonMissing?: string
}

export const AcDataExplorerCompanyDirectorsTab = ({
  hasReported,
  reasonMissing,
}: IAcDataExplorerCompanyDirectorsTab) => {
  if (!hasReported)
    return (
      <AcNoReporting
        type={`het ${LABELS.DIRECTORS.toLowerCase()}`}
        reasonMissing={reasonMissing}
      />
    )
  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={KEYS.DIRECTORS}
        title="Percentage vrouwen in het bestuur"
        subTitle={`Percentage vrouwen in het bestuur, einde boekjaar ${LABELS.CURRENT_YEAR}`}
      />
      <AcCompanyDetailDirectors
        title="Man-vrouwverhouding in het bestuur"
        subTitle={`Aantal statutair bestuurders (natuurlijke personen), einde boekjaar ${LABELS.CURRENT_YEAR}`}
        seats="Aantal bestuurders"
        subset={KEYS.DIRECTORS}
      />
      <AcCompanyDetailAppointment
        subset={KEYS.DIRECTORS}
        title="Benoemingen en herbenoemingen"
        subTitle={`Percentage vrouwen bij benoemingen, 
          herbenoemingen en aftredingen in het bestuur, in boekjaar ${LABELS.CURRENT_YEAR}`}
      />
    </>
  )
}
