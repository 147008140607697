import { useMemo } from 'react'

import { ICONS, KEYS, LABELS, TOOLTIPS } from '@constants'
import { IAnalyticsCompanyDepartments } from '@typings'
import { AcDataItem } from '../ac-data-item/ac-data-item'
import { AcCardWithTitle } from '../index.features.components'
import { AcIntlFloat } from '@helpers/ac-intl-number.helper'

interface IAcCardMaidenless {
  title: string
  subtitle: string
  seriesData: any
}

const getColorvar = (department: string) => {
  switch (department.toLowerCase()) {
    case KEYS.DIRECTORS:
      return '--ac-color-secondary-orange'
    case KEYS.SUBTOP:
      return '--ac-color-secondary-lightblue2'
    case KEYS.SUPERVISORY:
      return '--ac-color-secondary-green'
    case KEYS.ORGANISATION:
      return '--ac-color-secondary-fuchia'
    default: {
      return '--ac-color-secondary-green'
    }
  }
}

const getTitle = (percentage: number) => {
  const percentageFloat = AcIntlFloat(percentage, 1)
  return percentageFloat ? percentageFloat + '%' : '-'
}

const getTooltip = (department: string) => {
  switch (department) {
    case LABELS.DIRECTORS:
      return 'Bedrijven met een bestuur bestaande uit natuurlijke personen'
    case LABELS.SUPERVISORY:
      return 'Bedrijven met een RvC / niet-uitvoerende bestuurders'
    case LABELS.SUBTOP:
      return 'Bedrijven met 1 of meer werknemers in de subtop'

    default:
      return ''
  }
}

export const AcCardMaidenless = ({
  title,
  subtitle,
  seriesData,
}: IAcCardMaidenless) => {
  const renderDataItems = useMemo(() => {
    return seriesData.map((singleItem: any, i: number) => {
      const DEPARTMENT =
        singleItem.type?.toUpperCase() as IAnalyticsCompanyDepartments

      const departmentString = LABELS[DEPARTMENT]

      return (
        <AcDataItem
          key={i}
          title={getTitle(singleItem.percentage)}
          heading={departmentString}
          label={`${singleItem?.value?.toLocaleString('nl-NL')} bedrijven`}
          direction="horizontal"
          headingColor={getColorvar(singleItem.type)}
          toolTip={getTooltip(departmentString)}
          toolTipPlacement="heading"
        />
      )
    })
  }, [seriesData])

  return (
    <AcCardWithTitle
      icon={ICONS.FEMALES_ALT}
      title={title}
      subTitle={subtitle}>
      {renderDataItems}
    </AcCardWithTitle>
  )
}
