import {
  DAEX_COLORS_SECONDARY,
  DAEX_COLORS_SECONDARY_GRADIENT,
  KEYS,
  LABELS,
} from '@constants'
import { IAnalyticsCompanyFemalesYear } from '@typings'

export interface IAcChartBarFemalesMap {
  seriesData: IAnalyticsCompanyFemalesYear[]
  markLineData: {
    seriesName: string
    value: (number | string)[]
  }[]
  subset?: Omit<keyof IAnalyticsCompanyFemalesYear, 'year'>
}

export interface ISeries {
  name: string
  type: string
  data: number[]
  itemStyle: any
  label: any
  markLine: any
  barWidth: any
  barGap: string
}

type IBarSeriesKey = 'ORGANISATION' | 'DIRECTORS' | 'SUPERVISORY' | 'SUBTOP'

const BAR_KEYS = [
  KEYS.ORGANISATION,
  KEYS.DIRECTORS,
  KEYS.SUPERVISORY,
  KEYS.SUBTOP,
]

export const AcChartBarFemalesMap = ({
  seriesData = [],
  markLineData = [],
  subset,
}: IAcChartBarFemalesMap) => {
  const AcChartBarFemalesConfig = {
    isSubset: !!subset,
    years: [] as string[],
    series: [] as ISeries[],
  }

  // Loop over years in series
  for (const [index, series] of seriesData.entries()) {
    const { year, ...bars } = series
    const appendSeriesData = !!index

    AcChartBarFemalesConfig.years.push(year.toString())

    // Custom order of barKeys to match DAEX_COLORS_SECONDARY
    let visibleBarKeys: string[] = BAR_KEYS

    // If only a subset should be displayed, select only data of certain barKey
    if (subset) {
      if (visibleBarKeys.includes(subset as string))
        visibleBarKeys = [subset as string]
      else return AcChartBarFemalesConfig
    }

    // Loop over bars inside one year (organisation, directors, etc.)
    for (const [barIndex, _barKey] of visibleBarKeys.entries()) {
      const barKey = _barKey as keyof typeof bars
      const bar = series[barKey]

      // Add percentage to data array if series is already initialized in first year
      if (appendSeriesData) {
        AcChartBarFemalesConfig.series[barIndex].data.push(bar.percentage)
      } else {
        const markLine = markLineData.find(
          ({ seriesName }) => seriesName === barKey
        )

        let percentage = bar.percentage

        // @ts-expect-error: Can cast to string
        if (bar.percentage === '0.0' || bar.percentage === 0.0) {
          percentage = 0
        }

        if (!bar.percentage && bar.percentage !== 0) {
          // @ts-expect-error: Can cast to string
          percentage = KEYS.NO_DATA
        }

        AcChartBarFemalesConfig.series.push({
          name: LABELS[barKey.toUpperCase() as IBarSeriesKey],
          type: 'bar',
          data: [percentage],
          barWidth: 32,
          barGap: '150%',
          itemStyle: {
            borderRadius: [6, 6, 0, 0],
            color: subset
              ? DAEX_COLORS_SECONDARY_GRADIENT[
                  (BAR_KEYS as string[]).indexOf(subset as string)
                ]
              : undefined,
          },
          label: {
            position: 'bottom',
            show: !subset,
            formatter: ({ seriesName, value }: any) => {
              if (value === KEYS.NO_DATA)
                return `{bold|${seriesName}}\n${LABELS.NOT_APPLICABLE}`
              return `{bold|${seriesName}}\n${value
                ?.toString()
                ?.replace('.', ',')}%`
            },
            offset: [0, 10],
            color: 'inherit',
            fontSize: 15,
            lineHeight: 20,
            align: 'center',
            rich: {
              bold: {
                fontSize: 15,
                align: 'center',
                fontWeight: 'bold',
                color: DAEX_COLORS_SECONDARY[barIndex],
              },
            },
          },
          markLine: markLine
            ? {
                symbol: 'none',
                lineStyle: {
                  type: 'dotted',
                  width: 2,
                },
                label: { show: false },
                data: [
                  {
                    name: 'Avg',
                    value: markLine.value[0],
                    yAxis: markLine.value[0],
                  },
                ],
              }
            : void 0,
        })
      }
    }
  }

  return AcChartBarFemalesConfig
}
