import { useState, useEffect } from 'react'

import { BREAKPOINTS } from '@constants'

type IBreakpoint = (typeof BREAKPOINTS)[keyof typeof BREAKPOINTS]

export const AcUseBreakpoint = (breakpoint: IBreakpoint) => {
  const [mediaQuery, setMediaQuery] = useState<{ matches: boolean }>({
    matches: window.innerWidth > breakpoint,
  })

  useEffect(() => {
    const match = window.matchMedia(`(min-width: ${breakpoint}px)`)

    match.addEventListener('change', setMediaQuery)

    return () => match.removeEventListener('change', setMediaQuery)
  }, [])

  return !!mediaQuery?.matches
}
