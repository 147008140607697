import { useMemo } from 'react'

import styles from './ac-company-tabs.module.scss'

import { AcButtonBack, AcTabsHeader } from '@components'
import { useStore } from '@hooks/ac-use-store'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

export const AcCompanyTabs = observer(() => {
  const { analytics } = useStore()
  if (!analytics.companies) return null
  const navigate = useNavigate()

  const renderTabs = useMemo(() => {
    return analytics.companies.map(({ name, slug }) => ({
      label: name,
      disabled: false,
      slug,
    }))
  }, [analytics.companies])

  const handleTabChange = (slug: string) => {
    if (!slug || slug === analytics.current_active_tab) return
    if (analytics.loading) return
    navigate(`/bedrijven/${slug}`, {
      state: { fromTabClick: true },
      replace: true,
    })
  }

  return (
    <div className={styles['ac-company-tabs']}>
      <div>
        <AcButtonBack className={styles['ac-company-tabs-back-button']} />
      </div>
      <div className={styles['ac-company-tabs-inner']}>
        <AcTabsHeader
          tabs={renderTabs}
          loading={analytics.loading}
          activeTab={analytics.current_active_tab}
          onTabChange={(slug: string) => handleTabChange(slug)}
          withMaxWidth
        />
      </div>
    </div>
  )
})
