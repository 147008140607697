import { ICONS } from './icons.constants'
import { KEYS } from './keys.constants'
import { LABELS } from './labels.constants'

export const DAEX_LISTS = {
  COMPANY_DETAIL: {
    TABS: [
      {
        label: LABELS.ORGANISATION,
        key: KEYS.ORGANISATION,
        slug: KEYS.ORGANISATION,
      },
      {
        label: LABELS.DIRECTORS,
        key: KEYS.DIRECTORS,
        slug: KEYS.DIRECTORS,
      },
      {
        label: LABELS.SUPERVISORY,
        key: KEYS.SUPERVISORY,
        slug: KEYS.SUPERVISORY,
      },
      { label: LABELS.SUBTOP, key: KEYS.SUBTOP, slug: KEYS.SUBTOP },
      { label: LABELS.PLAN, key: KEYS.PLAN, slug: KEYS.PLAN },
    ],
  },
}

export const DAEX_COMPANY_PLAN: { [key: string]: string } = {
  code_1: 'In het bestuur',
  code_2: 'In de raad van commissarissen',
  code_3: 'Visie en leiderschap',
  code_4: 'Management en organisatie',
  code_5: 'Inclusieve bedrijfscultuur',
  code_6: 'Toelichting bij het plan van aanpak',
} as const

export const DAEX_INDEX_TITLES = {
  DATA_1: {
    TITLE: 'Percentage vrouwen in de top en subtop',
    SUBTITLE:
      'Gemiddeld percentage vrouwen in het bestuur, de RvC, subtop en organisatie, 2012-2022',
  },
  DATA_2: {
    TITLE: `M/V-samenstelling in de top en subtop ${LABELS.CURRENT_YEAR}`,
    SUBTITLE: `Gemiddeld percentage vrouwen en mannen in het bestuur, de RvC, subtop en organisatie, boekjaar ${LABELS.CURRENT_YEAR}, peildatum 31 december`,
  },
  DATA_3: {
    TITLE: 'Streefcijfers voor de top en subtop',
    SUBTITLE: 'Gemiddeld streefcijfer voor het bestuur, de RvC en de subtop',
  },
  DATA_4: {
    TITLE: 'Benoemingen en herbenoemingen in de top',
    SUBTITLE: `Percentage vrouwen bij benoemingen, herbenoemingen en aftredingen in het bestuur
    en de RvC, in boekjaar ${LABELS.CURRENT_YEAR}`,
  },
  DATA_5: {
    TITLE: 'In- en uitstroom subtop',
    SUBTITLE: `Percentage vrouwen dat aangesteld werd in de subtop (instroom)
     en dat de subtop verliet (uitstroom) in boekjaar ${LABELS.CURRENT_YEAR}`,
  },
  DATA_6: {
    TITLE: `Bedrijven zonder vrouwen in top en subtop`,
    SUBTITLE: `Percentage bedrijven zonder vrouwen in het bestuur, de RvC en de subtop, boekjaar ${LABELS.CURRENT_YEAR}, peildatum 31 december`,
  },
  DATA_7: {
    TITLE: `Plan van aanpak`,
    SUBTITLE: `Percentage bedrijven met een formeel plan van aanpak en percentage bedrijven dat dit plan heeft gedeeld, boekjaar ${LABELS.CURRENT_YEAR}`,
  },
}

export const DAEX_ICON_MAP = {
  [KEYS.APPOINTMENT]: ICONS.WORKSPACE_PREMIUM,
  [KEYS.REAPPOINTMENT]: ICONS.RESTART,
  [KEYS.RESIGN]: ICONS.LOGOUT,
  [KEYS.INFLUX]: ICONS.INFLUX,
  [KEYS.OUTFLOW]: ICONS.OUTFLOW,
}

export const DAEX_DEPARTMENTS: Record<string, Record<string, string>> = {
  WITH_LEADING_ARTICLE: {
    [KEYS.ORGANISATION]: 'de organisatie',
    [KEYS.SUPERVISORY]: 'de RvC',
    [KEYS.SUBTOP]: 'de subtop',
    [KEYS.DIRECTORS]: 'het bestuur',
  },
}

export const DAEX_TARGET_GROUPS = [
  '0%',
  '1-10%',
  '11-20%',
  '21-30%',
  '31-40%',
  '41-50%',
]
