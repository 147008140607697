export const DAEX_FOOTER = {
  LEVEL_1: {
    TITLE: 'Heb je vragen of opmerkingen?',
    PHONE: '070 - 2 002 532',
    MAIL: 'diversiteitsportaal@ser.nl',
    HELPDESK: {
      title: 'naar de helpdesk',
      href: 'https://www.ser.nl/nl/thema/diversiteitsportaal/helpdesk',
      external: true,
    },
  },
  LEVEL_2: {
    READ_MORE: 'Meer lezen?',
    READ_MORE_LINES: [
      {
        title: 'Publicaties',
        href: 'https://www.ser.nl/nl/thema/diversiteitsportaal/publicaties',
        external: true,
      },
      {
        title: `Wet 'Ingroeiquotum en streefcijfers'`,
        href: 'https://www.ser.nl/nl/thema/diversiteitsportaal/inhoud-wet',
        external: true,
      },
      {
        title: 'Nieuws',
        href: 'https://www.ser.nl/nl/thema/diversiteitsportaal/nieuws',
        external: true,
      },
    ],
    ADDRESS: 'Adres',
    ADDRESS_LINES: ['Postadres', 'Postbus 90405', '2509 LK Den Haag'],
  },
  LEVEL_3: [
    {
      title: 'Privacy statement',
      href: 'https://www.ser.nl/nl/privacy',
      external: true,
    },
    {
      title: 'Klachten',
      href: 'https://www.ser.nl/nl/klachten',
      external: true,
    },
  ],
}
