import { KEYS } from '@constants'
import { useStore } from '@hooks/ac-use-store'
import { IAnalyticsExplanationSubset } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcTypography } from '../../core/index.core.components'
import { AcCardWithTitle } from '../index.features.components'

interface IAcCompanyDetailExplanationProps {
  subset?: IAnalyticsExplanationSubset
}

export const AcCompanyDetailExplanationComponent = observer(
  ({ subset = KEYS.ORGANISATION }: IAcCompanyDetailExplanationProps) => {
    const { analytics } = useStore()

    const getTitle = useMemo(
      () =>
        subset === 'organisation'
          ? `Toelichting ${analytics.company.name}`
          : 'Definitie subtop',
      [subset, analytics.company.name]
    )

    const explanationData = useMemo(() => {
      if (subset === KEYS.ORGANISATION) return analytics.company.explanation
      return analytics.definition[subset]
    }, [subset, analytics.company.name, analytics.definition[subset]])

    const noData = useMemo(
      () =>
        subset === KEYS.ORGANISATION
          ? `Geen toelichting gegeven`
          : `Er is geen definitie gegeven`,
      [subset, analytics.company.name]
    )

    return (
      <AcCardWithTitle title={getTitle}>
        <AcTypography weight="light">
          {explanationData ? explanationData : noData}
        </AcTypography>
      </AcCardWithTitle>
    )
  }
)
