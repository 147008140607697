import { forwardRef } from 'react'
import styles from './ac-card-grid.module.scss'
import clsx from 'clsx'

interface IAcCardGrid {
  children: React.ReactNode
  maxWidth?: 'md' | 'lg'
  pt?: number
}

export const AcCardGrid = forwardRef(
  ({ children, maxWidth, pt }: IAcCardGrid, ref) => {
    return (
      <div
        ref={ref as React.ForwardedRef<HTMLDivElement>}
        className={clsx(
          styles['ac-card-grid'],
          styles[`ac-card-grid--max-${maxWidth}`]
        )}
        style={{ paddingTop: `${pt}rem` }}>
        {children}
      </div>
    )
  }
)
AcCardGrid.displayName = 'AcCardGrid'
