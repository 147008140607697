import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { KEYS, LABELS } from '@constants'
import { useStore } from '@hooks/ac-use-store'
import { AcButton, AcSelect, AcTitle } from '../../core/index.core.components'

import styles from './ac-index-filter.module.scss'

export const AcIndexFilter = observer(() => {
  const { analytics } = useStore()

  const renderSizeOptions = useMemo(() => {
    if (!analytics[KEYS.INDEX_FILTERS][KEYS.SIZES]?.length) return []

    const options = [
      {
        label: 'Totaal',
        value: '',
      },
    ]
    for (const option of analytics[KEYS.INDEX_FILTERS][KEYS.SIZES]) {
      options.push({ label: option.label, value: option.key })
    }

    return options
  }, [analytics[KEYS.INDEX_FILTERS]])

  const renderSectorOptions = useMemo(() => {
    if (!analytics[KEYS.INDEX_FILTERS][KEYS.SECTORS]?.length) return []

    const options = [
      {
        label: 'Totaal',
        value: '',
      },
    ]
    for (const option of analytics[KEYS.INDEX_FILTERS][KEYS.SECTORS]) {
      options.push({ label: option.label, value: option.key })
    }

    return options
  }, [analytics[KEYS.INDEX_FILTERS]])

  const handleSizeChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    analytics.setFilterQuery({ size: target.value })
  }

  const handleSectorChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    analytics.setFilterQuery({ sector: target.value })
  }

  const clearFilters = () => {
    analytics.setFilterQuery({ sector: '', size: '' })
  }

  return (
    <div className={styles['ac-index-filter']}>
      <div className={styles['ac-index-filter-wrapper']}>
        <AcTitle level={2}>Hoe staat het er voor?</AcTitle>
        <div className={styles['ac-index-filter-selects']}>
          <AcSelect
            label="Kies bedrijfsgrootte"
            name="size"
            options={renderSizeOptions}
            onChange={handleSizeChange}
            value={analytics.index_filter_query.size}
          />
          <AcSelect
            label="Kies bedrijfstak"
            name="sector"
            options={renderSectorOptions}
            onChange={handleSectorChange}
            value={analytics.index_filter_query.sector}
          />
          <div className={styles['ac-index-filter-action']}>
            <AcButton
              onClick={clearFilters}
              variant="link"
              label={LABELS.CLEAR_ALL}
              padding="none"
            />
          </div>
        </div>
      </div>
    </div>
  )
})
