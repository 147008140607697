import { BREAKPOINTS, ICONS, KEYS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { useStore } from '@hooks/ac-use-store'
import { IAnalyticsAppointmentsSubset } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcDataItem } from '../ac-data-item/ac-data-item'
import { AcCardWithTitle } from '../index.features.components'

interface IAppointmentItem {
  type: 'appointment' | 'reappointment' | 'resign'
  percentage: Nullable<number>
  value?: Nullable<number>
  total: Nullable<number>
}

interface IAppointmentData {
  directors: IAppointmentItem[]
  supervisory: IAppointmentItem[]
}

interface IAcCompanyDetailAppointmentProps {
  title: string
  subTitle?: string
  subset: IAnalyticsAppointmentsSubset
}

export const AcCompanyDetailAppointment = observer(
  ({
    title,
    subTitle,
    subset = KEYS.DIRECTORS,
  }: IAcCompanyDetailAppointmentProps) => {
    const { analytics } = useStore()
    const isDesktop = AcUseBreakpoint(BREAKPOINTS.SM)

    const currentData = useMemo(
      () => analytics.appointments[subset],
      [analytics.appointments, subset]
    )

    const getIcon = (type: IAppointmentItem['type']) => {
      switch (type) {
        case 'appointment':
          return ICONS.WORKSPACE_PREMIUM
        case 'reappointment':
          return ICONS.RESTART
        case 'resign':
          return ICONS.LOGOUT
      }
    }

    const getLabel = (
      type: IAppointmentItem['type'],
      value: IAppointmentItem['value']
    ) => {
      switch (type) {
        case 'appointment':
          return `${value} benoemingen`
        case 'reappointment':
          return `${value} herbenoemingen`
        case 'resign':
          return `${value} aftredingen`
      }
    }

    const currentColor = useMemo(() => {
      switch (subset) {
        case 'supervisory':
          return '--ac-color-secondary-green'
        default:
          return '--ac-color-secondary-orange'
      }
    }, [subset])

    return (
      <AcCardWithTitle
        title={title}
        subTitle={subTitle}>
        {currentData?.map(singleItem => (
          <AcDataItem
            key={singleItem.type}
            title={`${singleItem.percentage}%`}
            label={getLabel(singleItem.type, singleItem.value)}
            direction={isDesktop ? 'horizontal' : 'vertical'}
            iconColor={currentColor}
            icon={getIcon(singleItem.type)}
          />
        ))}
      </AcCardWithTitle>
    )
  }
)
