import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart, GaugeChart, LineChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { useMemo } from 'react'

import {
  BREAKPOINTS,
  DAEX_COLORS_SECONDARY_GRADIENT,
  LABELS,
  KEYS,
} from '@constants'
import { IAnalyticsCompanyFemalesYear } from '@typings'
import { AcChartBarFemalesMap } from './ac-chart-bar-females-map'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { AcPercentageString } from '@helpers/ac-percentage-string.helpers'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GaugeChart,
  BarChart,
  LineChart,
  MarkLineComponent,
  CanvasRenderer,
])

interface IAcChartBarFemales {
  seriesData: IAnalyticsCompanyFemalesYear[]
  markLineData: {
    seriesName: string
    value: (number | string)[]
  }[]
  subset?: Omit<keyof IAnalyticsCompanyFemalesYear, 'year'>
}

export const AcChartBarFemales = ({
  seriesData,
  markLineData,
  subset,
}: IAcChartBarFemales) => {
  const isMdAndUp = AcUseBreakpoint(BREAKPOINTS.MD)

  const renderChart = useMemo(() => {
    const charts: JSX.Element[] = []

    const addChart = (seriesData: IAnalyticsCompanyFemalesYear[]) => {
      const config = AcChartBarFemalesMap({
        seriesData,
        markLineData,
        subset,
      })

      // Add fake xAxis labels (years) to make the bars align left as per design
      if (isMdAndUp && subset)
        config.years = [
          ...config.years,
          ...Array(12 - seriesData.length).fill(''),
        ]

      const opts = {
        animationDelayUpdate: function (idx: number) {
          return idx * 5
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: (args: any) => {
            const markLineElements = markLineData
              .map(({ seriesName, value }) => {
                const targetValue =
                  value[0] === '-' ? '-' : `${AcPercentageString(value[0])}%`
                const targetYear = value[1]
                return `
                  <tr class="table-grid">
                  <td class="has-data">${
                    (LABELS as any)[seriesName?.toUpperCase()]
                  }<td/>
                  <td class="has-data">${targetValue}<td/>
                  <td class="has-data">${targetYear}<td/>
                  </tr>`
              })
              .join('')

            const tooltip = `
              <table class="tooltip">
                <thead>
                  <tr><th>${args[0].axisValue}</th><tr/>
                </thead>
              </table>
              <table class="tooltip">
                <thead>
                  <tr><th>Percentage vrouwen</th><tr/>
                </thead>
                <tbody>
                  ${args
                    .map(({ marker, seriesName, value }: any) => {
                      if (value === KEYS.NO_DATA) return null
                      return `
                      <tr class="table-grid">
                      <td class="has-data">${marker} ${seriesName}<td/>
                      <td class="has-data">${value
                        ?.toString()
                        ?.replace('.', ',')}%</td>
                      </tr>`
                    })
                    .join('')}
                  <tr><td></td></tr>
                </tbody>
              </table>
              <table class="tooltip">
              <thead >
                <tr><th>${LABELS.TARGET} & </th><th>${
              LABELS.TARGET_YEAR
            }</th><tr/>
              </thead>
              <tbody>
                ${markLineElements}
              </tbody>
            </table>`

            return tooltip
          },
        },
        textStyle: {
          fontFamily: 'Dax',
        },
        color: DAEX_COLORS_SECONDARY_GRADIENT,
        legend: {
          data: [LABELS.TARGET],
          formatter: LABELS.TARGET,
          selectedMode: false,
          right: 0,
        },
        grid: {
          bottom: 100,
          left: 40,
          right: 0,
        },
        xAxis: [
          {
            type: 'category',
            data: config.years,
            position: 'bottom',
            offset: config.isSubset ? 16 : 72,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              interval: 0,
              formatter: (value: any) =>
                `{${config.isSubset ? 'p' : 'h1'}|${value}}`,
              color: '#37474F',
              fontSize: 14,
              rich: {
                h1: {
                  fontSize: 18,
                  fontFamily: 'Dax Black',
                  fontWeight: 900,
                },
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 100,
            interval: 50,
            axisLabel: {
              formatter: '{value}%',
            },
            minorTick: {
              splitNumber: 2,
            },
            minorSplitLine: {
              show: true,
            },
          },
        ],
        series: [
          ...config.series,
          // For legend only
          {
            name: LABELS.TARGET,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'transparent',
            },
            lineStyle: {
              color: 'black',
              type: 'dotted',
            },
          },
        ],
      }

      charts.push(
        <ReactEChartsCore
          style={{ height: '420px' }}
          echarts={echarts}
          option={opts}
          key={config.years.join()}
        />
      )
    }

    if (isMdAndUp || subset) {
      // For desktop and subsets, render 1 chart
      addChart(seriesData)
    } else {
      // For tablet and below, render chart per year to be able to wrap
      for (const femalesYear of seriesData) {
        addChart([femalesYear])
      }
    }

    return <>{charts}</>
  }, [seriesData, markLineData, subset, isMdAndUp])

  return renderChart
}
