import clsx from 'clsx'
import { forwardRef, useMemo } from 'react'

import styles from './ac-card.module.scss'

interface IAcCard {
  className?: string
  children?: React.ReactNode
  element?: 'section' | 'div' | 'article'
  padding?: 'none' | 32
  elevation?: 'none' | 'sm' | 'md'
  theme?: 'light' | 'error'
  pb?: number
}

export const AcCard = forwardRef<HTMLDivElement, IAcCard>(
  (
    {
      className,
      element: Element = 'div',
      children,
      padding = 32,
      elevation = 'md',
      theme = 'light',
      pb,
    }: IAcCard,
    ref
  ) => {
    const getCardClassNames = useMemo(
      () =>
        clsx([
          className,
          styles['ac-card'],
          styles[`ac-card--padding-${padding}`],
          styles[`ac-card--elevation-${elevation}`],
          styles[`ac-card--theme-${theme}`],
        ]),
      [className, padding, elevation]
    )
    return (
      <Element
        ref={ref}
        className={getCardClassNames}
        style={{ paddingBottom: pb ? pb + 'rem' : void 0 }}>
        {children}
      </Element>
    )
  }
)

AcCard.displayName = 'AcCard'
