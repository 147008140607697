import clsx from 'clsx'
import { createElement, useMemo } from 'react'
import styles from './ac-title.module.scss'

interface IAcTitle {
  children: React.ReactNode
  /** @default 1 */
  level?: 1 | 2 | 3 | 4
  className?: string
  style?: React.CSSProperties
}

export const AcTitle = ({
  children,
  level = 1,
  className,
  style,
}: IAcTitle) => {
  const getTitleClassNames = useMemo(
    () => clsx(styles['ac-title'], styles[`ac-title--${level}`], className),
    [level, className]
  )

  const element = useMemo(
    () =>
      createElement(
        `h${level}`,
        { className: getTitleClassNames, style },
        children
      ),
    [level, children, style, getTitleClassNames]
  )

  return element
}
