import { DAEX_COMPANY_PLAN, ICONS } from '@constants'
import { AcCard, AcShimmer, AcTitle, AcTypography } from '@components'
import { useStore } from '@hooks/ac-use-store'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcIcon } from 'src/components/core/ac-icon/ac-icon'

import styles from './data-explorer-company-approach-tab.module.scss'

export const AcDataExplorerCompanyApproachTab = observer(() => {
  const { analytics } = useStore()
  const itemKeys = useMemo(() => {
    return analytics.plan.answers ? Object.keys(analytics?.plan?.answers) : []
  }, [analytics.plan])

  const sections = [
    [itemKeys[0], itemKeys[1]],
    [itemKeys[2], itemKeys[3], itemKeys[4]],
    [itemKeys[5]],
  ]

  const SingleSection = ({ itemKey }: { itemKey: string }) => {
    const object = analytics.plan.answers[itemKey]
    const title = DAEX_COMPANY_PLAN[itemKey]

    const isSubtopOrOrganistion = itemKey.match(/code_[3-5]/)

    return (
      <div>
        {isSubtopOrOrganistion ? (
          <AcTypography
            size="md"
            weight="semibold"
            color="primary"
            className={
              styles['ac-data-explorer-company-approach-tab-section-subtitle']
            }>
            {title}
          </AcTypography>
        ) : (
          <AcTitle
            level={4}
            className={
              styles['ac-data-explorer-company-approach-tab-section-title']
            }>
            {title}
          </AcTitle>
        )}
        {object.body && (
          <AcTypography
            element="span"
            className={
              styles['ac-data-explorer-company-approach-tab-section-body']
            }>
            {object.body}
          </AcTypography>
        )}
        {object.activities && (
          <AcCard
            className={styles['ac-data-explorer-company-approach-tab-activity']}
            elevation="none"
            padding="none">
            {object.activities.length ? (
              <>
                <h3
                  className={
                    styles[
                      'ac-data-explorer-company-approach-tab-activity-title'
                    ]
                  }>
                  Beleid en maatregelen
                </h3>
                {object.activities.map(singleActivity => {
                  const getItemClasses = () =>
                    clsx(
                      styles[
                        'ac-data-explorer-company-approach-tab-activity-item'
                      ],
                      singleActivity.type &&
                        styles[
                          // eslint-disable-next-line max-len
                          `ac-data-explorer-company-approach-tab-activity-item--${singleActivity.type}`
                        ],
                      styles[
                        // eslint-disable-next-line max-len
                        `ac-data-explorer-company-approach-tab-activity-item--${singleActivity.color}`
                      ]
                    )
                  return (
                    <div
                      key={singleActivity.id}
                      className={
                        styles[
                          'ac-data-explorer-company-approach-tab-activity-item-wrapper'
                        ]
                      }>
                      <div
                        style={{
                          borderColor: singleActivity.color,
                        }}
                        data-color-fill={singleActivity.color}
                        className={getItemClasses()}>
                        <span
                          className={
                            styles[
                              'ac-data-explorer-company-approach-tab-activity-item-inner'
                            ]
                          }>
                          <p
                            className={
                              styles[
                                'ac-data-explorer-company-approach-tab-activity-item-label'
                              ]
                            }>
                            {singleActivity.label}
                          </p>
                          {singleActivity.type && (
                            <AcIcon
                              className={
                                styles[
                                  // eslint-disable-next-line max-len
                                  `ac-data-explorer-company-approach-tab-activity-item-icon-${singleActivity.type}`
                                ]
                              }
                              icon={
                                singleActivity.type === 'focus1'
                                  ? ICONS.STAR
                                  : ICONS.WARNING
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <AcTypography
                className={
                  styles[
                    'ac-data-explorer-company-approach-tab-activity-item-empty'
                  ]
                }>
                Geen rapportage over beleid en maatregelen
              </AcTypography>
            )}
          </AcCard>
        )}
      </div>
    )
  }

  return (
    <AcCard className={styles['ac-data-explorer-company-approach-tab']}>
      <AcTitle
        level={3}
        style={{ marginBottom: '20px' }}>
        Plan van aanpak om de streefcijfers te realiseren
      </AcTitle>
      <section
        className={
          styles['ac-data-explorer-company-approach-tab-grid-container']
        }>
        {!itemKeys.length ? (
          <AcShimmer />
        ) : (
          sections.map((singleSection, i) => {
            return (
              <>
                {i === 1 && (
                  <AcTitle
                    level={4}
                    className={
                      styles['ac-data-explorer-company-approach-tab-grid-title']
                    }>
                    Subtop & organisatie
                  </AcTitle>
                )}
                <section
                  className={
                    styles['ac-data-explorer-company-approach-tab-grid-section']
                  }
                  key={i}
                  data-item-length={singleSection.length}>
                  {singleSection.map(singleItem => (
                    <SingleSection
                      key={singleItem}
                      itemKey={singleItem}
                    />
                  ))}
                </section>
              </>
            )
          })
        )}
      </section>
      <section
        className={styles['ac-data-explorer-company-approach-tab-bottom']}>
        <div>
          <AcIcon
            className={
              styles[
                // eslint-disable-next-line max-len
                `ac-data-explorer-company-approach-tab-activity-item-icon-focus1`
              ]
            }
            icon={ICONS.STAR}
          />
          <AcTypography element="span">Zijn succesvol</AcTypography>
        </div>
        <div>
          <AcIcon
            className={
              styles[
                // eslint-disable-next-line max-len
                `ac-data-explorer-company-approach-tab-activity-item-icon-focus2`
              ]
            }
            icon={ICONS.WARNING}
          />
          <AcTypography element="span">Heeft meer aandacht nodig</AcTypography>
        </div>
      </section>
      <AcTypography>
        Disclaimer: aan dit plan van aanpak kunnen geen rechten worden ontleend.
      </AcTypography>
    </AcCard>
  )
})
