import logo from '@assets/logos/ser_logo_standalone.svg'
import { AcIcon, AcTypography } from '../../core/index.core.components'
import { DAEX_FOOTER, ICONS } from '@constants'

import styles from './ac-footer-daex.module.scss'

export const AcFooterDaex = () => {
  return (
    <footer className={styles['ac-footer-daex']}>
      <div className={styles['ac-footer-daex-before']}>
        <AcTypography>{DAEX_FOOTER.LEVEL_1.TITLE}</AcTypography>
        <a href={`tel:${DAEX_FOOTER.LEVEL_1.PHONE?.replace(/(-| )/gm, '')}`}>
          <AcIcon icon={ICONS.CALL} />
          <AcTypography>{DAEX_FOOTER.LEVEL_1.PHONE}</AcTypography>
        </a>
        <a href={`mailto:${DAEX_FOOTER.LEVEL_1.MAIL}`}>
          <AcIcon icon={ICONS.MAIL} />
          <AcTypography>{DAEX_FOOTER.LEVEL_1.MAIL}</AcTypography>
        </a>
        <a
          href={DAEX_FOOTER.LEVEL_1.HELPDESK.href}
          target="_blank"
          rel="noreferrer">
          <AcIcon icon={ICONS.HEADSET} />
          <AcTypography element="span">
            {DAEX_FOOTER.LEVEL_1.HELPDESK.title}
          </AcTypography>
          <AcIcon
            icon={ICONS.ARROW_UP_RIGHT}
            size={11}
          />
        </a>
      </div>
      <div className={styles['ac-footer-daex-info']}>
        <div className={styles['ac-footer-daex-info-container']}>
          <img
            className={styles['ac-footer-daex-info-logo']}
            src={logo}
            alt="Sociaal Economische Raad"
          />
          <div className={styles['ac-footer-daex-info-content']}>
            <div className={styles['ac-footer-daex-info-content-left']}>
              <AcTypography>{DAEX_FOOTER.LEVEL_2.READ_MORE}</AcTypography>
              {DAEX_FOOTER.LEVEL_2.READ_MORE_LINES.map((link, index) => (
                <a
                  href={link.href}
                  key={index}
                  target="_blank"
                  rel="noreferrer">
                  <AcTypography element="span">{link.title}</AcTypography>
                  <AcIcon
                    icon={ICONS.ARROW_UP_RIGHT}
                    size={11}
                  />
                </a>
              ))}
            </div>
            <div className={styles['ac-footer-daex-info-content-right']}>
              <AcTypography>Adres</AcTypography>
              {DAEX_FOOTER.LEVEL_2.ADDRESS_LINES.map((line, index) => (
                <AcTypography
                  key={index}
                  element="span">
                  {line}
                </AcTypography>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['ac-footer-daex-links']}>
        <div className={styles['ac-footer-daex-links-container']}>
          {DAEX_FOOTER.LEVEL_3.map((link, index) => (
            <a
              href={link.href}
              key={index}
              target="_blank"
              rel="noreferrer">
              <AcTypography element="span">{link.title}</AcTypography>
            </a>
          ))}
        </div>
      </div>
    </footer>
  )
}
