import { ROUTE_PATHS } from '@constants'
import { AcAnalyticsCompanyHeading, AcCard } from '@components'
import { IAnalyticsSearchResult } from '@typings'
import { Link, generatePath } from 'react-router-dom'
import styles from './ac-search-card.module.scss'

export const AcSearchCard = (company: IAnalyticsSearchResult) => {
  if (!company.slug) return null

  return (
    <Link
      to={{
        pathname: generatePath(ROUTE_PATHS.ANALYTICS_COMPANY_DETAIL, {
          slug: company.slug,
        }),
      }}
      className={styles['ac-search-card']}>
      <AcCard>
        <AcAnalyticsCompanyHeading {...company} />
      </AcCard>
    </Link>
  )
}
