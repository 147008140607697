import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { KEYS } from '@constants'
import { AcTypography } from '@components'
import { IAnalyticsPagination } from '@typings'
import { AcPaginationPrevNextButton } from './ac-pagination-prev-next-button'

import styles from './ac-pagination.module.scss'

interface IAcDataGridPaginationProps {
  pagination: IAnalyticsPagination
  store: any
}

export const AcPagination = observer(
  ({ pagination, store }: IAcDataGridPaginationProps) => {
    const getVisibleRange = useMemo(() => {
      const { current_page, last_page } = pagination
      const delta = 1
      const range = 4
      const left = current_page - delta
      const right = current_page + delta

      const pages = []
      const pagesWithDots = []
      let pageLength

      for (let page = 1; page <= last_page; page++) {
        // if is first page
        if (page === 1) pages.push(page)
        // if is last page
        else if (page === last_page) pages.push(page)
        // if is in range of delta (left = current - delta, right = current + delta)
        else if (page >= left && page <= right) pages.push(page)
        // if is in range (start)
        else if (current_page <= range && page <= range) pages.push(page)
        // if is in range (end (last_page - range)
        else if (current_page >= last_page - range && page >= last_page - range)
          pages.push(page)
      }

      for (const page of pages) {
        // l is undefined on first iteration, so we always show page 1
        if (pageLength) {
          // check equals
          // current indice - previous === 2 (difference between delta left and first page)
          // current indice - previous === 2 (difference between delta right and last page)
          // then we show that page as well
          if (page - pageLength === 2) {
            pagesWithDots.push(pageLength + 1)

            // otherwise we check if the difference is not exactly 2, and we show the ellipsis
          } else if (page - pageLength !== 1) {
            pagesWithDots.push('...')
          }
        }
        pagesWithDots.push(page)
        pageLength = page
      }

      return pagesWithDots
    }, [pagination.current_page, pagination.last_page])

    const getNumberRange = useMemo(() => {
      return (
        <>
          {getVisibleRange.map(pageNumber =>
            typeof pageNumber === 'string' ? (
              <span
                key={`${pageNumber}-${Math.random()}`}
                className={styles['ac-pagination-dots']}>
                {pageNumber}
              </span>
            ) : (
              <button
                className={clsx(
                  styles['ac-pagination-number'],
                  pageNumber === pagination.current_page &&
                    styles['ac-pagination-number--active']
                )}
                key={pageNumber}
                onClick={() => store.handlePagination(pageNumber)}>
                <AcTypography
                  element="span"
                  size="md"
                  color="primary"
                  weight={
                    pageNumber === pagination.current_page
                      ? 'semibold'
                      : 'light'
                  }>
                  {pageNumber.toString()}
                </AcTypography>
              </button>
            )
          )}
        </>
      )
    }, [
      pagination.current_page,
      pagination.total,
      pagination.last_page,
      getVisibleRange,
      store,
    ])

    return (
      <div className={styles['ac-pagination']}>
        <span>
          <AcPaginationPrevNextButton
            direction={KEYS.PREVIOUS}
            disabled={!(store.pagination?.current_page > 1)}
            onClick={() => store.handlePagination(KEYS.PREVIOUS)}
          />
        </span>
        <div className={styles['ac-pagination-numbers']}>{getNumberRange}</div>
        <span>
          <AcPaginationPrevNextButton
            direction={KEYS.NEXT}
            disabled={
              !(store.pagination.current_page < store.pagination.last_page)
            }
            onClick={() => store.handlePagination(KEYS.NEXT)}
          />
        </span>
      </div>
    )
  }
)
