import { ReactComponent as ChevronDown } from '@assets/icons/chevron-down.svg'
import { ReactComponent as ChevronLeft } from '@assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from '@assets/icons/chevron-right.svg'
import { ReactComponent as ChevronUp } from '@assets/icons/chevron-up.svg'
import { ReactComponent as CheckCircleIcon } from '@assets/icons/check-circle.svg'
import { ReactComponent as FemalesIcon } from '@assets/icons/females.svg'
import { ReactComponent as GroupIcon } from '@assets/icons/group.svg'
import { ReactComponent as IndustryIcon } from '@assets/icons/industry.svg'
import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg'
import { ReactComponent as MagnifierIcon } from '@assets/icons/magnifier.svg'
import { ReactComponent as MalesIcon } from '@assets/icons/males.svg'
import { ReactComponent as MonitoringIcon } from '@assets/icons/monitoring.svg'
import { ReactComponent as RestartIcon } from '@assets/icons/restart-alt.svg'
import { ReactComponent as StatsIcon } from '@assets/icons/stats.svg'
import { ReactComponent as SuitcaseIcon } from '@assets/icons/suitcase.svg'
import { ReactComponent as WorkspacePremiumIcon } from '@assets/icons/workspace-premium.svg'
import { ReactComponent as CallIcon } from '@assets/icons/call.svg'
import { ReactComponent as HeadsetIcon } from '@assets/icons/headset.svg'
import { ReactComponent as MailIcon } from '@assets/icons/mail.svg'
import { ReactComponent as FemalesAltIcon } from '@assets/icons/females-alt.svg'
import { ReactComponent as ArrowUpRightIcon } from '@assets/icons/arrow-up-right.svg'
import { ReactComponent as AvatarIcon } from '@assets/icons/avatar.svg'
import { ReactComponent as InfluxIcon } from '@assets/icons/influx.svg'
import { ReactComponent as OutflowIcon } from '@assets/icons/outflow.svg'
import { ReactComponent as PvaIcon } from '@assets/icons/pva_icon.svg'
import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg'

import StarIcon from '@mui/icons-material/Star'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const ICON_ELEMENTS = {
  CHECK_CIRCLE: CheckCircleIcon,
  AVATAR: AvatarIcon,
  ARROW_UP_RIGHT: ArrowUpRightIcon,
  CHEVRON_UP: ChevronUp,
  CHEVRON_DOWN: ChevronDown,
  CHEVRON_LEFT: ChevronLeft,
  CHEVRON_RIGHT: ChevronRight,
  WORKSPACE_PREMIUM: WorkspacePremiumIcon,
  LOGOUT: LogoutIcon,
  RESTART: RestartIcon,
  FEMALES: FemalesIcon,
  FEMALES_ALT: FemalesAltIcon,
  INFO: InfoIcon,
  INDUSTRY: IndustryIcon,
  GROUP: GroupIcon,
  STATS: StatsIcon,
  MAGNIFIER: MagnifierIcon,
  MONITORING: MonitoringIcon,
  SUITCASE: SuitcaseIcon,
  MALES: MalesIcon,
  STAR: StarIcon,
  STAR_OUTLINE: StarOutlineOutlinedIcon,
  WARNING: WarningAmberIcon,
  CALL: CallIcon,
  HEADSET: HeadsetIcon,
  MAIL: MailIcon,
  INFLUX: InfluxIcon,
  OUTFLOW: OutflowIcon,
  PVA: PvaIcon,
  CALENDAR: CalendarIcon,
}

export const ICONS = {
  AVATAR: 'AVATAR',
  ARROW_UP_RIGHT: 'ARROW_UP_RIGHT',
  CHEVRON_LEFT: 'CHEVRON_LEFT',
  CHEVRON_RIGHT: 'CHEVRON_RIGHT',
  CHEVRON_UP: 'CHEVRON_UP',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  CHECK_CIRCLE: 'CHECK_CIRCLE',
  GROUP: 'GROUP',
  MONITORING: 'MONITORING',
  FEMALES: 'FEMALES',
  FEMALES_ALT: 'FEMALES_ALT',
  MALES: 'MALES',
  SUITCASE: 'SUITCASE',
  INFO: 'INFO',
  WORKSPACE_PREMIUM: 'WORKSPACE_PREMIUM',
  RESTART: 'RESTART',
  LOGOUT: 'LOGOUT',
  INDUSTRY: 'INDUSTRY',
  STATS: 'STATS',
  MAGNIFIER: 'MAGNIFIER',
  STAR: 'STAR',
  STAR_OUTLINE: 'STAR_OUTLINE',
  WARNING: 'WARNING',
  CALL: 'CALL',
  HEADSET: 'HEADSET',
  MAIL: 'MAIL',
  INFLUX: 'INFLUX',
  OUTFLOW: 'OUTFLOW',
  PVA: 'PVA',
  CALENDAR: 'CALENDAR',
} as const
