// Styles
import { AcLayoutWrapper } from '@components'
import { Outlet } from 'react-router'

const AppLayout = () => {
  return (
    <AcLayoutWrapper>
      <Outlet />
    </AcLayoutWrapper>
  )
}

export default AppLayout
