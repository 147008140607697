import { AnalyticsApi } from './analytics.api'

export interface IApi {
  analytics: AnalyticsApi
}

export default (): IApi => {
  const analytics = new AnalyticsApi()
  return {
    analytics,
  }
}
