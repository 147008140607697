import styles from './ac-card-row.module.scss'

interface IAcCardRow {
  template: string
  children: React.ReactNode
}

export const AcCardRow = ({ template, children }: IAcCardRow) => {
  return (
    <div
      className={styles['ac-card-row']}
      style={
        {
          '--template': template,
        } as React.CSSProperties
      }>
      {children}
    </div>
  )
}
