import clsx from 'clsx'
import { useMemo } from 'react'

import { ICONS, KEYS, LABELS } from '@constants'
import { AcIcon, AcTypography } from '../../core/index.core.components'

import styles from './ac-pagination.module.scss'

interface IAcPaginationPrevNextButton {
  className?: string
  direction?: typeof KEYS.PREVIOUS | typeof KEYS.NEXT
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const AcPaginationPrevNextButton = ({
  className,
  direction = KEYS.PREVIOUS,
  disabled,
  onClick,
}: IAcPaginationPrevNextButton) => {
  const isNext = useMemo(() => direction === KEYS.NEXT, [direction])

  return (
    <button
      className={clsx(styles['ac-pagination-prev-next-button'], className)}
      disabled={disabled}
      onClick={onClick}>
      {!isNext && <AcIcon icon={ICONS.CHEVRON_LEFT} />}
      <AcTypography weight={disabled ? 'light' : 'semibold'}>
        {isNext ? LABELS.NEXT : LABELS.PREVIOUS}
      </AcTypography>
      {isNext && <AcIcon icon={ICONS.CHEVRON_RIGHT} />}
    </button>
  )
}
