import styles from './ac-tabs-header.module.scss'

import { BREAKPOINTS, ICONS, LABELS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import clsx from 'clsx'
import { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { AcIcon } from '../ac-icon/ac-icon'
import { AcButton, AcCard } from '../index.core.components'
import { AcTabLabel } from './ac-tab-label/ac-tab-label'

export interface IAcSingleTab {
  label: string
  key?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: (props?: any) => Nullable<JSX.Element>
  disabled: boolean
  slug: string
}

export interface IAcTabsHeader {
  tabs: IAcSingleTab[]
  onTabChange: (slug: IAcSingleTab['slug']) => void
  errors?: IAcSingleTab['slug'][] | Set<IAcSingleTab['slug']>
  className?: string
  activeTab?: IAcSingleTab['slug']
  withMaxWidth?: boolean
  showMoreLabel?: string
  loading?: boolean
}

export const AcTabsHeader = ({
  tabs,
  className,
  onTabChange,
  activeTab,
  withMaxWidth = false,
  showMoreLabel,
  loading = false,
}: IAcTabsHeader) => {
  //Create ref for active indicator
  const tabActiveIndicatorRef = createRef<HTMLHRElement>()
  //Create ref for showAllButton, used by popper
  const [showAllButtonRef, setShowAllButtonRef] =
    useState<HTMLButtonElement | null>(null)
  //Ref for outer container
  const outerRef = createRef<HTMLDivElement>()
  //Ref for outer elementContainer (wrapper of elementWrapper)
  const outerElementWrapperRef = createRef<HTMLDivElement>()
  //Ref for elementWrapper, children are the tabs
  const elementWrapperRef = createRef<HTMLDivElement>()
  //Ref for the pop-out menu, triggered by showAll
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null)
  //Property to keep track of the visibility of nav, set to true when tabs overflow their parent.
  const [navVisible, setNavVisible] = useState(false)
  //Check to see if currentView is desktop
  const isDesktop = AcUseBreakpoint(BREAKPOINTS.SM)
  //State to manage the showing of the showAll menu
  const [showAll, setShowAll] = useState(false)

  //The offset calculated based on viewport. Offset is used to display the showAll menu
  const menuOffset: any = useMemo(() => {
    return isDesktop ? [16, 40] : [0, 24]
  }, [isDesktop])

  //The popper object for the showAll menu
  const { styles: menuStyles, attributes } = usePopper(
    showAllButtonRef,
    menuRef,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: menuOffset,
          },
        },
      ],
    }
  )

  //Create an array of objects with a tab, and ref so it can be targeted
  const _tabElements = useMemo(
    () =>
      tabs.map(singleTab => ({
        ref: createRef<HTMLButtonElement>(),
        tab: singleTab,
      })),
    [tabs]
  )

  //Get the index of the current activeTab. This is used for the previous and next buttons.
  const getCurrentActiveTabIndex = useCallback(
    () => _tabElements.findIndex(({ tab }) => tab.slug === activeTab),
    [_tabElements, activeTab]
  )

  //The function that is fired when a tab is clicked.
  const handleTabClick = (
    element: HTMLButtonElement | null,
    tab: IAcSingleTab
  ) => {
    if (tab.slug !== activeTab) {
      onTabChange(tab.slug)
      // if (element) element.scrollIntoView({ inline: 'center' })
    }
  }

  //Function to handle the navigation of tabs(prev and next) used by tabNav
  const handleTabNav = (dir: 'prev' | 'next') => {
    const currentTabLength = _tabElements.length
    const currentActiveTabIndex = getCurrentActiveTabIndex()
    const newIndex =
      dir === 'prev' ? currentActiveTabIndex - 1 : currentActiveTabIndex + 1
    const nextTab = _tabElements && _tabElements[newIndex]

    if (!(newIndex > currentTabLength) || !(newIndex < 0)) {
      handleTabClick(nextTab.ref.current, nextTab.tab)
    }
    return
  }

  //Observer to check if the tabs overflow their parent. if so, we show the navigation.
  const outerWrapperResizeObserver = new ResizeObserver(
    (elements: ResizeObserverEntry[]) => {
      for (const element of elements) {
        if (elementWrapperRef.current !== null) {
          const lastChild = _tabElements
            .at(-1)
            ?.ref?.current?.getBoundingClientRect()
          const wrapper = elementWrapperRef.current.getBoundingClientRect()
          const needsMenu = lastChild
            ? lastChild.right > wrapper.width + wrapper.x
            : false

          if (needsMenu && !navVisible) {
            setNavVisible(true)
          }
          if (!needsMenu && navVisible) {
            setNavVisible(false)
          }
        }
      }
    }
  )

  //The resize observer function, used to position the activeIndicator
  const resizeObserver = new ResizeObserver(
    (elements: ResizeObserverEntry[]) => {
      for (const element of elements) {
        if (tabActiveIndicatorRef.current) {
          const { x, bottom } = (
            tabActiveIndicatorRef.current.parentElement as HTMLElement
          ).getBoundingClientRect()

          const targetRect = (
            element.target as HTMLElement
          ).getBoundingClientRect()

          tabActiveIndicatorRef.current.style.width = targetRect.width + 'px'
          tabActiveIndicatorRef.current.style.transform = `translate(
            ${targetRect.left - x}px, ${targetRect.bottom - bottom}px)`
        }
      }
    }
  )

  //The function that defines the tabHeaders outer div's classes
  const getTabHeaderClasses = useMemo(
    () =>
      clsx(
        styles['ac-tabs-header'],
        withMaxWidth && styles['ac-tabs-header--max-width'],
        navVisible && withMaxWidth && styles['ac-tabs-header--nav-visible'],
        className && className
      ),
    [className, withMaxWidth]
  )

  //The memoized function to render the tabs.
  const renderShowAllElements = useMemo(() => {
    const getButtonClasses = (active: boolean) =>
      clsx(
        styles['ac-tabs-header-show-all-button'],
        active && styles['ac-tabs-header-show-all-button--active']
      )
    return _tabElements.map(({ tab, ref }) => (
      <button
        className={getButtonClasses(activeTab === tab.slug)}
        key={tab.slug}
        color={'secondary'}
        disabled={loading}
        onClick={() => {
          if (activeTab !== tab.slug) {
            setShowAll(false)
            handleTabClick(ref.current, tab)
          }
        }}
        data-label={tab.label}>
        {tab.label}
      </button>
    ))
  }, [tabs, activeTab])

  //The effect that adds the observers to elements, and handles the scrollposition
  useEffect(() => {
    if (activeTab) {
      const activeTabElement = _tabElements.find(
        el => el.tab.slug === activeTab
      )?.ref.current
      if (resizeObserver && activeTabElement) {
        resizeObserver.observe(activeTabElement)
        activeTabElement.scrollIntoView({
          block: 'nearest',
          inline: 'center',
          behavior: 'smooth',
        })
        return () => resizeObserver.unobserve(activeTabElement)
      }
    }
    if (tabs && !activeTab && tabs?.[0]) onTabChange(tabs?.[0]?.slug)
  }, [activeTab, _tabElements])

  //Add observer to the elementWrapperRef
  useEffect(() => {
    if (elementWrapperRef.current !== null) {
      outerWrapperResizeObserver.observe(elementWrapperRef?.current)
    }
  }, [outerElementWrapperRef])

  return (
    <div
      className={getTabHeaderClasses}
      ref={outerRef}
      //Set tabIndex as data attribute for styling
      data-tab-index={
        getCurrentActiveTabIndex() + 1 !== tabs.length
          ? getCurrentActiveTabIndex()
          : 'last'
      }>
      <div className={styles['ac-tabs-header-nav-left']}>
        {navVisible && withMaxWidth && (
          <>
            {getCurrentActiveTabIndex() !== 0 && (
              <button
                disabled={loading}
                className={styles['ac-tabs-header-nav-button']}
                onClick={() => handleTabNav('prev')}>
                <AcIcon icon={ICONS.CHEVRON_LEFT} />
              </button>
            )}
          </>
        )}
      </div>
      <div
        className={styles['ac-tabs-header-inner']}
        ref={outerElementWrapperRef}>
        <div
          className={styles['ac-tabs-header-inner-elements']}
          ref={elementWrapperRef}>
          {_tabElements.map(({ ref, tab }) => (
            <AcTabLabel
              active={activeTab === tab.slug}
              onClick={() => handleTabClick(ref.current, tab)}
              ref={ref}
              key={tab.slug}
              loading={loading}
              {...tab}>
              {tab.label}
            </AcTabLabel>
          ))}
          <hr
            ref={tabActiveIndicatorRef}
            className={styles['ac-tabs-header__active-indicator']}
          />
        </div>
      </div>
      <div className={styles['ac-tabs-header-nav-right']}>
        {navVisible && withMaxWidth && (
          <>
            {getCurrentActiveTabIndex() + 1 !== tabs.length && (
              <button
                disabled={loading}
                className={styles['ac-tabs-header-nav-button']}
                onClick={() => handleTabNav('next')}>
                <AcIcon icon={ICONS.CHEVRON_RIGHT} />
              </button>
            )}
            {isDesktop ? (
              <AcButton
                ref={setShowAllButtonRef}
                color={showAll ? 'primary' : 'secondary'}
                className={styles['ac-tabs-header-nav-right-show-all']}
                padding="chip"
                label={showMoreLabel ? showMoreLabel : LABELS.ALL_OPTIONS}
                variant="pill"
                icon={showAll ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                onClick={() => setShowAll(!showAll)}
                disabled={loading}
              />
            ) : (
              <button
                ref={setShowAllButtonRef}
                className={styles['ac-tabs-header-nav-right-show-all--mobile']}
                onClick={() => setShowAll(!showAll)}
                disabled={loading}>
                <AcIcon
                  icon={showAll ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                />
              </button>
            )}
            {showAll && withMaxWidth && (
              <>
                {/* We use a portal because of the parents overflow hidden property. */}
                {createPortal(
                  <div
                    ref={setMenuRef}
                    {...attributes.popper}
                    style={{ ...menuStyles.popper }}>
                    <AcCard className={styles['ac-tabs-header-show-all']}>
                      <div className={styles['ac-tabs-header-show-all-inner']}>
                        {renderShowAllElements}
                      </div>
                    </AcCard>
                  </div>,
                  document.getElementById('root') as HTMLElement
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
