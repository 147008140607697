import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import {
  AcCardBarTarget,
  AcCardCircleFemales,
  AcCardGrid,
  AcCardLineFemales,
  AcCardMaidenless,
  AcCardPva,
  AcCardRow,
  AcInfluxOutflowCard,
  AcHelmet,
} from '@components'
import { DAEX_INDEX_TITLES, DAEX_META, KEYS } from '@constants'
import { useStore } from '@hooks/ac-use-store'

// eslint-disable-next-line max-len
import { AcAppointmentCard } from '@components'

export const AcDataExplorerIndex = observer(() => {
  const { analytics } = useStore()

  useEffect(() => {
    analytics.resetSearch()
    analytics.getIndexFilters()
  }, [])

  useEffect(() => {
    analytics.getIndex()
  }, [analytics[KEYS.INDEX_FILTER_QUERY]])

  return (
    <div>
      <AcHelmet
        title={DAEX_META.INDEX.TITLE}
        description={DAEX_META.INDEX.DESCRIPTION}
      />
      <AcCardGrid
        pt={2.5}
        maxWidth="lg">
        <AcCardLineFemales
          title={DAEX_INDEX_TITLES.DATA_1.TITLE}
          subtitle={DAEX_INDEX_TITLES.DATA_1.SUBTITLE}
          seriesData={analytics[KEYS.INDEX_FEMALES]}
          visible={!analytics.is_index_filtered}
        />
        <AcCardCircleFemales
          title={DAEX_INDEX_TITLES.DATA_2.TITLE}
          subtitle={DAEX_INDEX_TITLES.DATA_2.SUBTITLE}
          seriesData={analytics[KEYS.INDEX_AVERAGE]}
        />
        <AcCardBarTarget
          title={DAEX_INDEX_TITLES.DATA_3.TITLE}
          subtitle={DAEX_INDEX_TITLES.DATA_3.SUBTITLE}
          seriesData={analytics[KEYS.INDEX_TARGET]}
          visible={!analytics.is_size_filtered}
        />
        <AcCardRow template="minmax(auto, 67.5%) minmax(auto, 32.5%)">
          <AcAppointmentCard
            title={DAEX_INDEX_TITLES.DATA_4.TITLE}
            subtitle={DAEX_INDEX_TITLES.DATA_4.SUBTITLE}
            seriesData={analytics[KEYS.INDEX_APPOINTMENTS]}
          />
          <AcInfluxOutflowCard
            title={DAEX_INDEX_TITLES.DATA_5.TITLE}
            subtitle={DAEX_INDEX_TITLES.DATA_5.SUBTITLE}
          />
        </AcCardRow>
        <AcCardRow template="1fr 1fr">
          <AcCardMaidenless
            title={DAEX_INDEX_TITLES.DATA_6.TITLE}
            subtitle={DAEX_INDEX_TITLES.DATA_6.SUBTITLE}
            seriesData={analytics[KEYS.INDEX_MAIDENLESS]}
          />
          <AcCardPva
            title={DAEX_INDEX_TITLES.DATA_7.TITLE}
            subtitle={DAEX_INDEX_TITLES.DATA_7.SUBTITLE}
            seriesData={analytics[KEYS.INDEX_PVA]}
          />
        </AcCardRow>
      </AcCardGrid>
    </div>
  )
})
