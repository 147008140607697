import { useCallback, useEffect, useState } from 'react'

let timeout: ReturnType<typeof setTimeout>

export const AcUseViewportWidth = () => {
  const [screensize, setScreenSize] = useState(window.innerWidth)

  const handleResize = useCallback(() => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => setScreenSize(window.innerWidth), 500)
    return timeout
  }, [timeout])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return screensize
}
