import { IAnalyticsCompany } from '@typings'

const API = '/api'
const ANALYTICS = '/analytics'
const COMPANIES = '/companies'
const DIRECTORS = '/directors'
const AVERAGE = '/average'
const TARGET = '/target'
const SEARCH = '/search'
const PLAN = '/plan'
const EMPLOYEES = '/employees'
const SUBTOP = '/subtop'
const APPOINTMENTS = '/appointments'
const FEMALES = '/females'
const DEFINITION = '/definition'
const FLOW = '/flow'
const MAIDENLESS = '/maidenless'
const PVA = '/pva'

export const ENDPOINTS = {
  ANALYTICS: {
    INDEX_FILTERS: () => `${API}${ANALYTICS}`,
    INDEX_AVERAGE: () => `${API}${ANALYTICS}${AVERAGE}`,
    INDEX_TARGET: () => `${API}${ANALYTICS}${TARGET}`,
    INDEX_SUBTOP: () => `${API}${ANALYTICS}${SUBTOP}`,
    INDEX_APPOINTMENTS: () => `${API}${ANALYTICS}${APPOINTMENTS}`,
    INDEX_FEMALES: () => `${API}${ANALYTICS}${FEMALES}`,
    INDEX_FLOW: () => `${API}${ANALYTICS}${FLOW}`,
    INDEX_MAIDENLESS: () => `${API}${ANALYTICS}${MAIDENLESS}`,
    INDEX_PVA: () => `${API}${ANALYTICS}${PVA}`,
    DETAIL_APPOINTMENTS: (slug: IAnalyticsCompany['slug']) =>
      `${API}${ANALYTICS}/${slug}${APPOINTMENTS}`,
    DETAIL_DIRECTORS: (slug: IAnalyticsCompany['slug']) =>
      `${API}${ANALYTICS}/${slug}${DIRECTORS}`,
    DETAIL_EMPLOYEES: (slug: IAnalyticsCompany['slug']) =>
      `${API}${ANALYTICS}/${slug}${EMPLOYEES}`,
    DETAIL_SUBTOP: (slug: IAnalyticsCompany['slug']) =>
      `${API}${ANALYTICS}/${slug}${SUBTOP}`,
    DETAIL_DEFINITION: (slug: IAnalyticsCompany['slug']) =>
      `${API}${ANALYTICS}/${slug}${DEFINITION}`,
    SEARCH: () => `${API}${ANALYTICS}${SEARCH}`,
    DETAIL_PLAN: (slug: IAnalyticsCompany['slug']) =>
      `${API}${ANALYTICS}/${slug}${PLAN}`,
    COMPANY_SLUG: (slug: string) => `${API}${ANALYTICS}/${slug}`,
    FEMALES: (slug: string) => `${API}${ANALYTICS}/${slug}${FEMALES}` as const,
    COMPANIES: (slug: string) => `${API}${ANALYTICS}/${slug}${COMPANIES}`,
  },
}
