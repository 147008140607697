import {
  AcButton,
  AcCardGrid,
  AcHelmet,
  AcTitle,
  AcTypography,
} from '@components'
import { DAEX_ABOUT, DAEX_META } from '@constants'

export const AcDataExplorerAboutView = () => {
  const data_url =
    'https://www.ser.nl/-/media/ser/downloads/thema/diversiteitsportaal/ser-scorecard-2024-genderbalans-bedrijfsleven.pdf'

  const handleDownload = () => window.open(data_url, '_blank')

  return (
    <AcCardGrid>
      <AcHelmet
        title={DAEX_META.ABOUT.TITLE}
        description={DAEX_META.ABOUT.DESCRIPTION}
      />
      <div>
        <AcTitle level={4}>{DAEX_ABOUT.TITLE.SUB}</AcTitle>
        <AcTitle>{DAEX_ABOUT.TITLE.MAIN}</AcTitle>
      </div>
      <div>
        <AcTypography
          size="lg"
          color="primary">
          {DAEX_ABOUT.INTRO}
        </AcTypography>
      </div>
      <div>
        <div>
          <AcTitle level={3}>Wat is de SER Dataverkenner?</AcTitle>
          <AcTypography>
            Bedrijven rapporteren sinds 2022 jaarlijks over de
            man-vrouwverhouding in het bestuur, de RvC en de subtop volgens de
            wet 'Ingroeiquotum en streefcijfers'. Ook geven ze jaarlijks door
            welke streefcijfers ze hebben opgesteld en welke plannen ze hebben
            om deze ambities te behalen. De SER Dataverkenner toont de
            informatie uit de jaarlijkse rapportages van deze grote
            vennootschappen.
          </AcTypography>
          <AcTypography>
            In deze online omgeving kun je de ontwikkeling van bedrijven zien.
            Je kunt de cijfers van zowel individuele bedrijven als sectoren en
            voor heel Nederland bekijken en vergelijken.
          </AcTypography>
        </div>
        <div>
          <AcTitle level={3}>Waarom rapporteren aan de SER?</AcTitle>
          <AcTypography>
            Via de dataverkenner kunnen bedrijven zich aan elkaar spiegelen.
            Door te rapporteren aan de SER zullen bedrijven bijdragen aan
            inzicht in de ontwikkeling van genderdiversiteit in de Nederlandse
            bedrijfstop. Dat kan voor bedrijven nuttig zijn bij het ontwikkelen
            van intern diversiteitsbeleid.
          </AcTypography>
          <AcTypography>
            Transparantie over de inzet voor meer diversiteit is belangrijk voor
            het behalen van een evenwichtiger man-vrouwverhouding in de top en
            subtop. Door gerapporteerde gegevens in een vast format te
            verzamelen en daarna openbaar en toegankelijk te maken in de
            dataverkenner, kunnen bedrijven van elkaar leren en weten andere
            partijen de stand van zaken met betrekking tot genderdiversiteit in
            de (sub)top van Nederlandse bedrijven.
          </AcTypography>
        </div>
        <div>
          <AcTitle level={3}>
            SER{' '}
            <em style={{ fontSize: 'inherit' }}>
              Monitor Genderbalans in het bedrijfsleven
            </em>
          </AcTitle>
          <AcTypography>
            De SER brengt jaarlijks de SER Monitor Genderbalans in het
            bedrijfsleven uit. De SER Scorecard maakt hiervan onderdeel uit en
            vat de resultaten van bedrijven die dat jaar hebben gerapporteerd
            samen. Welke veranderingen zijn zichtbaar in het aandeel vrouwen in
            de top en subtop en de ambities die daarvoor worden gesteld? Ook
            brengt de SER daaropvolgend een uitgebreidere SER rapportage uit met
            meer informatie over de data, het veldwerk en de analysemethode.
          </AcTypography>
          <div style={{ paddingBottom: 'var(--ac-pixel-size-16)' }}>
            <AcButton
              label="SER Scorecard 2024"
              onClick={handleDownload}
            />
          </div>
        </div>
        <div>
          <AcTitle level={3}>
            Toelichting op de data in de SER dataverkenner
          </AcTitle>
          <AcTypography>
            De SER dataverkenner toont de streefcijfers en ontwikkelingen van de
            man-vrouwverhouding in de top en subtop op verschillende niveaus:
            (1) per bedrijf, (2) op het niveau van de sector en bedrijfsgrootte
            en (3) voor Nederland als geheel.
          </AcTypography>
          <AcTypography>
            <em>1. Ontwikkeling genderbalans per bedrijf</em>
          </AcTypography>
          <AcTypography>
            De rapportages die bedrijven hebben ingediend via het SER
            diversiteitsportaal worden rechtstreeks en zonder tussenkomst van de
            SER getoond via de bedrijfspagina's in de dataverkenner. De
            rapportages over het boekjaar 2022 zijn in te zien vanaf 29 januari
            2024. Het aantal rapportages in de SER Dataverkenner kan na die
            datum veranderen, o.a. omdat bedrijven met een gebroken boekjaar op
            een later moment mogen rapporteren.
          </AcTypography>
          <AcTypography>
            Bedrijven die onder de wet vallen, rapporteren vanaf boekjaar 2022
            verplicht. Daarnaast rapporteren sommige organisaties vrijwillig.
            Dat zijn bijvoorbeeld stichtingen, coöperaties en vennootschappen
            die niet als 'groot' kwalificeren en die deel uitmaken van een groep
            en gezamenlijk willen rapporteren met groepsmaatschappijen die wel
            onder de wet vallen.
          </AcTypography>
          <AcTypography>
            <em>
              2. Ontwikkeling genderbalans in Nederland, per sector en
              bedrijfsgrootte
            </em>
          </AcTypography>
          <AcTypography>
            De ontwikkelingen in Nederland, per sector en bedrijfsgrootte zijn
            geanalyseerd op basis van de rapportages die bedrijven hebben
            ingediend. Voor de analyses in de dataverkenner is een selectie
            gemaakt van bedrijven uit de SER dataverkenner, de zogenoemde
            'analysegroep'.
          </AcTypography>
          <AcTypography>
            <strong>Analysegroep</strong>: Voor de analyses over boekjaar 2022
            zijn uit de SER dataverkenner alle bedrijven geselecteerd die (1) in
            2022 aan de wet moesten voldoen én (2) die hun rapportage over
            boekjaar 2022 uiterlijk 15 december 2023 bij de SER hadden
            ingediend. Vanwege de vergelijkbaarheid zijn vervolgens (3)
            uitsluitend bedrijven geselecteerd met een boekjaar dat eindigde op
            31 december.
          </AcTypography>
          <AcTypography>
            Publieke organisaties, stichtingen, coöperaties en vennootschappen
            die niet als 'groot' kwalificeerden en die vrijwillig hebben
            gerapporteerd, zijn niet in de analyses meegenomen. Rapportages met
            ontbrekende of ongeldige gegevens zijn eveneens uit de analyses
            verwijderd.
          </AcTypography>
          <AcTypography>
            In totaal zijn de rapportages van 2.057 vennootschappen over
            boekjaar 2022 geanalyseerd. Voor de analyses op sectorniveau konden
            de rapportages van 3 vennootschappen niet worden meegenomen omdat
            sectorinformatie niet compleet was. Het aantal ingediende
            rapportages in de SER dataverkenner is dus groter dan het aantal
            rapportages waarop de analyses voor Nederland, sectoren en
            bedrijfsgroottes zijn gebaseerd.
          </AcTypography>
          <AcTypography>
            <strong>Groepen die gezamenlijk rapporteren</strong>
            <br />
            Bedrijven die tot een groep behoren, konden kiezen voor één
            gezamenlijke rapportage die door het groepshoofd werd ingevuld en
            waarin de groepsmaatschappijen die onder de wet vallen werden
            betrokken. In dat geval konden de volgende gegevens op groepsniveau
            worden gerapporteerd:
          </AcTypography>
          <AcTypography element="span">
            <ul>
              <li>
                streefcijfers: het groepshoofd stelt een streefcijfer op voor
                het bestuur en de RvC dat geldt voor alle besturen en RvC's in
                de groep;
              </li>
              <li>
                bedrijfsgrootte: het groepshoofd rapporteert over het totaal
                aantal werknemers in de organisatie (in personen);
              </li>
              <li>
                definitie van de subtop: het groepshoofd definieert de subtop op
                het niveau van de groep;
              </li>
              <li>
                plan van aanpak: het groepshoofd rapporteert over een plan van
                aanpak dat geldt voor alle in de rapportage betrokken
                groepsmaatschappijen. Het groepshoofd hoeft daar maar een keer
                over te rapporteren.
              </li>
            </ul>
          </AcTypography>
          <AcTypography>
            Over de man-vrouwverhouding in het bestuur en de RvC werd door het
            hoofd van de groep voor alle groepsmaatschappijen apart
            gerapporteerd.
          </AcTypography>
          <AcTypography>
            De SER Dataverkenner toont de informatie waarover het groepshoofd op
            groepsniveau heeft gerapporteerd zowel voor het hoofd, als voor de
            groepsmaatschappijen die in de gezamenlijke rapportage zijn
            betrokken. Zo is hetzelfde plan van aanpak te bekijken op de
            bedrijfspagina van het groepshoofd én op de bedrijfspagina's van de
            betrokken groepsmaatschappijen. Dat geldt ook voor de definitie van
            de subtop en het aantal werknemers.
          </AcTypography>
          <AcTypography>
            Voor de analyses voor Nederland, sector en bedrijfsgrootte die in de
            SER dataverkenner worden getoond, telde de informatie uit een
            groepsrapportage over het aantal werknemers, de subtop en het plan
            van aanpak telkens maar één keer mee in de analyses. Het percentage
            vrouwen in de subtop van een bedrijf dat gezamenlijk rapporteert
            voor 5 groepsmaatschappijen, telt bijvoorbeeld maar één keer mee bij
            de berekening van het gemiddelde percentage vrouwen in de subtop in
            Nederland.
          </AcTypography>
          <AcTypography>
            Meer informatie over de data, het veldwerk en de analysemethode is
            opgenomen in de SER Monitor Genderbalans in het bedrijfsleven 2024
            die in het voorjaar 2024 verschijnt. Deze zal te downloaden zijn via{' '}
            <a
              href="www.diversiteitsportaal.nl"
              target="_blank">
              www.diversiteitsportaal.nl
            </a>
          </AcTypography>
        </div>
        <div>
          <AcTitle level={3}>Toelichting begrippen</AcTitle>
          <AcTypography>
            <strong>Genderdiversiteit</strong>: Bedrijven kunnen rekening houden
            met personen die zich niet in thuis voelen binnen het klassieke
            man-vrouwhokje. Gelet op de privacy, kan het onwenselijk zijn als
            dit soort gegevens te herleiden zijn tot bepaalde personen. Daarom
            konden bedrijven personen uit deze categorie meetellen bij het
            ondervertegenwoordigde geslacht.
          </AcTypography>
          <AcTypography>
            <strong>Grote vennootschap</strong>: Een vennootschap is volgens het
            jaarrekeningrecht 'groot' als de jaarrekening op twee opeenvolgende
            balansdata, zonder onderbreking nadien op twee opeenvolgende
            balansdata, voldoet aan ten minste twee van de volgende criteria:
          </AcTypography>
          <AcTypography element="span">
            <ul>
              <li>de waarde van de activa bedraagt meer dan € 20 miljoen;</li>
              <li>de netto-omzet is meer dan € 40 miljoen;</li>
              <li>het gemiddeld aantal werknemers is 250 of meer.</li>
            </ul>
          </AcTypography>
          <AcTypography>
            <strong>Streefcijfer</strong>: De wet verplicht grote
            vennootschappen zelf passende en ambitieuze streefcijfers te
            formuleren voor de het bestuur, de RvC en de subtop. Een
            streefcijfer is een minimumpercentage dat geldt voor vrouwen én
            mannen. Een streefcijfer van 40% m/v voor het bestuur betekent dus
            dat het bestuur voor ten minste 40% uit vrouwen en voor ten minste
            40% uit mannen moet bestaan. 'Passend' wil zeggen dat het
            streefcijfer afhangt van de omvang van het bestuur, de RvC of de
            subtop én van de huidige man-vrouwverhouding.
          </AcTypography>
          <AcTypography>
            <strong>Subtop</strong>: Grote vennootschappen stellen streefcijfers
            op voor werknemers in leidinggevende posities, de zogenoemde subtop.
            Zij mogen zelf bepalen wat zij onder de subtop verstaan en voor
            welke subtop zij streefcijfers vaststellen. Groepen die gezamenlijk
            rapporteren definiëren de subtop op het niveau van de groep. In
            eerder onderzoek van de Bedrijvenmonitor gold voor de subtop een
            vaste definitie, namelijk de eerste twee lagen onder het bestuur. De
            gegevens over de subtop in boekjaar 2022 zijn daarom niet een-op-een
            vergelijkbaar met die uit eerdere jaren.
          </AcTypography>
          <AcTypography>
            <strong>Formeel plan van aanpak</strong>: Onder een formeel plan van
            aanpak wordt een zichzelf staand beleid verstaan of beleid dat is
            opgenomen in een ander beleidsplan. Het beleid moet zijn beschreven
            en goedgekeurd door het bestuur.
          </AcTypography>
          <AcTypography>
            <strong>Aftreden</strong>: bestuurders of commissarissen die het
            bestuur of de RvC gedurende het boekjaar hebben verlaten,
            bijvoorbeeld omdat de zittingstermijn is verstreken of omdat zij -
            al dan niet vrijwillig - tussentijds zijn opgestapt.
          </AcTypography>
          <AcTypography>
            <strong>Instroom en uitstroom subtop</strong>:{' '}
          </AcTypography>
          <AcTypography element="span">
            <ul>
              <li>
                Instroom: Het aantal werknemers (in personen) dat gedurende het
                afgelopen boekjaar is aangesteld in een functie in de subtop.
                Het gaat zowel om personen van buiten de organisatie als om
                werknemers uit de eigen organisatie die werden aangesteld in een
                functie in de subtop en die voorheen geen functie in de subtop
                hadden.
              </li>
              <li>
                Uitstroom: het aantal werknemers (in personen) dat gedurende het
                afgelopen jaar de subtop heeft verlaten. Het gaat zowel om
                werknemers die binnen de organisatie doorstroomden naar een
                functie die niet tot de subtop wordt gerekend als om werknemers
                die de organisatie hebben verlaten.
              </li>
            </ul>
          </AcTypography>
        </div>
      </div>
    </AcCardGrid>
  )
}
