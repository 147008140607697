import { LABELS } from '@constants'
import { Helmet } from 'react-helmet'

export const AcHelmet = ({ title = '', description = '' }) => {
  let renderTitle = title

  renderTitle += LABELS.CORPERATE_TITLE_APPENDIX

  return (
    <Helmet>
      <title>{renderTitle}</title>
      <meta
        name="description"
        content={description}
      />
    </Helmet>
  )
}
