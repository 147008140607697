import { action, makeAutoObservable } from 'mobx'
import { createContext } from 'react'

import Api, { IApi } from '@api'
import { saveToStorage } from '@helpers/browser-storage.helpers'

import { AnalyticsStore } from './analytics.stores'

export class Store {
  api: IApi
  analytics: AnalyticsStore

  constructor() {
    makeAutoObservable(this)
    this.api = Api()
    this.analytics = new AnalyticsStore(this)
  }

  @action
  set<S extends keyof Store, K extends keyof Store[S], A extends Store[S][K]>(
    store: S,
    key: K,
    value: A,
    save?: boolean
  ) {
    if (!key || !store) return
    ;(this as Store)[store][key] = value
    if (save) saveToStorage(key as string, value)
  }
}

export const StoreContext = createContext<Store>(new Store())
