import { AcCardGrid, AcHelmet, AcTitle } from '@components'
import { DAEX_404, DAEX_META } from '@constants'

export const AcDataExplorer404 = () => {
  return (
    <AcCardGrid>
      <AcHelmet
        title={DAEX_META.NOT_FOUND.TITLE}
        description={DAEX_META.NOT_FOUND.DESCRIPTION}
      />
      <div>
        <AcTitle>{DAEX_404.TITLE.MAIN}</AcTitle>
      </div>
      <div>{DAEX_404.CONTENT}</div>
    </AcCardGrid>
  )
}
