import { LABELS } from './labels.constants'

export const TOOLTIPS = {
  DAEX_INDEX: {
    TARGET: {
      DIRECTORS: `Percentage bedrijven naar de hoogte van het streefcijfer voor het bestuur`,
      SUPERVISORY: `Percentage bedrijven naar de hoogte van het streefcijfer voor de RvC`,
      SUBTOP: `Percentage bedrijven naar de hoogte van het streefcijfer voor de subtop`,
    },
    TOP_SUBTOP: {
      DIRECTORS: {
        APPOINTMENT: `${LABELS.CURRENT_YEAR} 
          percentage vrouwen bij benoemingen in het bestuur`,
        RESIGN: `${LABELS.CURRENT_YEAR} 
          percentage vrouwen bij aftredingen in het bestuur`,
        REAPPOINTMENT: `${LABELS.CURRENT_YEAR} 
          percentage vrouwen bij herbenoemingen in het bestuur`,
      },
      SUPERVISORY: {
        APPOINTMENT: `${LABELS.CURRENT_YEAR} 
          percentage vrouwen bij benoemingen in de RvC`,
        RESIGN: `${LABELS.CURRENT_YEAR} 
          percentage vrouwen bij aftredingen in de RvC`,
        REAPPOINTMENT: `${LABELS.CURRENT_YEAR} 
          percentage vrouwen bij herbenoemingen in de RvC`,
      },
      SUBTOP: {
        INFLUX: [
          `${LABELS.CURRENT_YEAR}, percentage vrouwen dat is aangesteld in de subtop`,
          `(in procenten van het totaal aantal werknemers dat is aangesteld in de subtop)`,
        ],
        OUTFLOW: [
          `${LABELS.CURRENT_YEAR}, percentage vrouwen dat de subtop heeft verlaten`,
          `(in procenten van het totaal aantal werknemers dat de subtop heeft verlaten)`,
        ],
      },
    },
    MAIDENLESS: {
      N_DIRECTORS: (n: number) =>
        `Percentage bedrijven zonder vrouwen in het bestuur ${n}`,
      N_SUPERVISORY: (n: number) =>
        `Percentage bedrijven zonder vrouwen in de RvC ${n}`,
      N_SUBTOP: (n: number) =>
        `Percentage bedrijven zonder vrouwen in de subtop ${n}`,
    },
  },
} as const
