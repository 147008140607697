import { KEYS, LABELS } from '@constants'
import {
  AcCompanyDetailExplanationComponent,
  AcCompanyDetailEmployees,
  AcCompanyDetailSubtopFlow,
  AcCompanyDetailWomenChartComponent,
  AcNoReporting,
} from '@components'

export interface IAcDataExplorerCompanySubtopTab {
  hasReported: boolean
  reasonMissing?: string
}

export const AcDataExplorerCompanySubtopTab = ({
  hasReported,
  reasonMissing,
}: IAcDataExplorerCompanySubtopTab) => {
  if (!hasReported)
    return (
      <AcNoReporting
        type={`de ${LABELS.SUBTOP.toLowerCase()}`}
        reasonMissing={reasonMissing}
      />
    )

  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={KEYS.SUBTOP}
        title="Percentage vrouwen in de subtop"
        subTitle={`Percentage vrouwen in de subtop, einde boekjaar ${LABELS.CURRENT_YEAR}`}
      />
      <AcCompanyDetailExplanationComponent subset={KEYS.SUBTOP} />
      <AcCompanyDetailEmployees
        title="Man-vrouwverhouding in de subtop"
        subTitle={`Aantal werknemers in de subtop (in personen), einde boekjaar ${LABELS.CURRENT_YEAR}`}
        subset={KEYS.SUBTOP}
      />
      <AcCompanyDetailSubtopFlow
        title="In- en uitstroom subtop"
        subTitle={`Percentage vrouwen dat aangesteld werd 
          in de subtop (instroom) en dat de subtop verliet (uitstroom) in boekjaar ${LABELS.CURRENT_YEAR}`}
      />
    </>
  )
}
