import { ICON_ELEMENTS } from '@constants'
import styles from './ac-icon.module.scss'
import { useMemo } from 'react'
import clsx from 'clsx'

interface IAcIconProps {
  icon: keyof typeof ICON_ELEMENTS
  size?: number
  className?: string
}

export const AcIcon = ({ icon, size = 18, className }: IAcIconProps) => {
  const Icon = ICON_ELEMENTS[icon]

  const iconClasses = useMemo(
    () => clsx(styles['ac-icon'], className && className),
    [className]
  )

  if (!Icon) return null
  return (
    <i
      style={{ '--size': `${size}px` } as React.CSSProperties}
      className={iconClasses}>
      <Icon className={styles['ac-icon-element']} />
    </i>
  )
}
