import {
  AcCompanyDetailExplanationComponent,
  AcCompanyDetailEmployees,
  AcCompanyDetailWomenChartComponent,
} from '@components'

export const AcDataExplorerCompanyOrganisationTab = () => {
  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={undefined}
        title="Percentage vrouwen in de top en subtop"
        subTitle="Percentage vrouwen in het bestuur, de RvC, subtop en organisatie, einde boekjaar 2022"
      />
      <AcCompanyDetailExplanationComponent />
      <AcCompanyDetailEmployees
        title="Aantal werknemers"
        subTitle="Totaal aantal werknemers in de organisatie, einde boekjaar 2022"
      />
    </>
  )
}
