import { Children, useMemo } from 'react'
import {
  AcCard,
  AcDivider,
  AcIcon,
  AcTitle,
  AcTypography,
} from '../../core/index.core.components'
import { ICONS } from '@constants'

import styles from './ac-card-with-title.module.scss'

interface IAcCardWithTitleProps {
  icon?: keyof typeof ICONS
  title?: string
  subTitle?: string
  children?: React.ReactNode
}

export const AcCardWithTitle = ({
  icon,
  title,
  subTitle,
  children,
}: IAcCardWithTitleProps) => {
  const childLength = useMemo(() => {
    const length = Children.count(children)

    return length > 4 ? 4 : length
  }, [children])

  return (
    <AcCard className={styles['ac-card-with-title']}>
      <div className={styles['ac-card-with-title-header']}>
        {icon && (
          <AcIcon
            size={72}
            icon={icon}
            className={styles['ac-card-with-title-icon']}
          />
        )}
        {(title || subTitle) && (
          <>
            <div className={styles['ac-card-with-title-title-wrapper']}>
              <AcTitle level={3}>{title}</AcTitle>
              {subTitle && (
                <AcTypography
                  className={styles['ac-card-with-title-title-sub']}
                  element="span"
                  weight="light">
                  {subTitle}
                </AcTypography>
              )}
            </div>
          </>
        )}
      </div>
      <AcDivider />
      <div
        className={styles['ac-card-with-title-content']}
        data-grid-count={childLength}>
        {children}
      </div>
    </AcCard>
  )
}
