import { BREAKPOINTS, KEYS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { useStore } from '@hooks/ac-use-store'
import { AcUseVisible } from '@hooks/ac-use-visible'
import { IAnalyticsCompanyFemalesYear } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo, useRef } from 'react'
import {
  AcCard,
  AcDivider,
  AcTitle,
  AcTypography,
} from '../../core/index.core.components'
import { IAcChartBarFemalesMap } from '../ac-chart-bar-females/ac-chart-bar-females-map'
import { AcChartBarFemales } from '../index.features.components'

interface IAcCompanyDetailWomenChartComponenProps {
  subset?: IAcChartBarFemalesMap['subset']
  title?: string
  subTitle?: string
}

export const AcCompanyDetailWomenChartComponent = observer(
  ({ subset, title, subTitle }: IAcCompanyDetailWomenChartComponenProps) => {
    const { analytics } = useStore()
    const isMdAndUp = AcUseBreakpoint(BREAKPOINTS.SM)
    const containerRef = useRef(null)
    const isVisible = AcUseVisible(containerRef.current)

    const renderChartBarFemales = useMemo(() => {
      // if (!isVisible || !analytics.females) return null

      const charts: JSX.Element[] = []

      const markLineData = [
        {
          seriesName: KEYS.DIRECTORS,
          value: [
            analytics.company?.targets?.directors?.target || '-',
            analytics.company?.targets?.directors?.year || '-',
          ],
        },
        {
          seriesName: KEYS.SUPERVISORY,
          value: [
            analytics.company?.targets?.supervisory?.target || '-',
            analytics.company?.targets?.supervisory?.year || '-',
          ],
        },
        {
          seriesName: KEYS.SUBTOP,
          value: [
            analytics.company?.targets?.subtop?.target || '-',
            analytics.company?.targets?.subtop?.year || '-',
          ],
        },
      ]

      const filteredMarkLineData = subset
        ? markLineData.filter(({ seriesName }) => seriesName === subset)
        : markLineData

      const addChart = (seriesData: IAnalyticsCompanyFemalesYear[]) => {
        charts.push(
          <AcChartBarFemales
            {...{
              seriesData,
              markLineData: filteredMarkLineData,
              subset,
            }}
          />
        )
      }
      if (isMdAndUp || subset) {
        // For desktop and subsets, render 1 chart
        addChart(analytics.females)
      } else {
        // For tablet and below, render chart per year to be able to wrap
        for (const femalesYear of analytics.females) {
          addChart([femalesYear])
        }
      }

      return charts
    }, [isVisible, isMdAndUp, analytics.females, subset])

    return (
      <AcCard>
        <AcTitle level={3}>{title}</AcTitle>
        <AcTypography weight="light">{subTitle}</AcTypography>
        <AcDivider />
        {renderChartBarFemales}
      </AcCard>
    )
  }
)
