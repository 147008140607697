import { useMemo } from 'react'
import { AcIcon, AcTitle, AcTypography } from '@components'
import { IAnalyticsCompany, IAnalyticsSearchResult } from '@typings'
import { LABELS } from '@constants'

import styles from './ac-analytics-company-heading.module.scss'

export const AcAnalyticsCompanyHeading = (
  company: IAnalyticsSearchResult | IAnalyticsCompany
) => {
  const bookyearEndDate = useMemo(() => {
    if (!company?.bookyear_end_date) return null
    return new Date(company.bookyear_end_date)?.toLocaleDateString('nl-NL', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
  }, [])

  return (
    <>
      <AcTitle level={2}>{company.name}</AcTitle>
      <div className={styles['ac-analytics-company-heading-meta']}>
        <AcTypography
          element="span"
          weight="light">
          <AcIcon icon="INDUSTRY" />
          {LABELS.SECTOR}: {company.sector || '-'}
        </AcTypography>
        <AcTypography
          element="span"
          weight="light">
          <AcIcon icon="STATS" />
          {LABELS.STOCK_EXCHANGE_LISTING}:{' '}
          {company.has_stock_exchange_listing
            ? LABELS.YES
            : company.has_stock_exchange_listing === null
            ? '-'
            : LABELS.NO}
        </AcTypography>
        <AcTypography
          element="span"
          weight="light">
          <AcIcon icon="GROUP" />
          {LABELS.SIZE_CLASS}: {(company.size && company.size + '') || '-'}
        </AcTypography>
        {bookyearEndDate && (
          <AcTypography
            element="span"
            weight="light">
            <AcIcon icon="CALENDAR" />
            {LABELS.BOOKYEAR_END_DATE}: {bookyearEndDate}
          </AcTypography>
        )}
      </div>
    </>
  )
}
