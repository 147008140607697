import { autorun, set, toJS } from 'mobx'

const _type_of_storage = process.env.REACT_APP_STORAGE || 'local'
const _storage =
  _type_of_storage === 'local' ? window.localStorage : window.sessionStorage

export const autoSave = (
  store: unknown,
  callback: CallableFunction,
  key: string
): void | unknown => {
  if (!store || !key) return

  let firstRun = true

  autorun(() => {
    // This code will run every time any observable property
    // in the provided store is updated
    const data = JSON.stringify(toJS(store))

    if (!firstRun && callback) {
      callback(data)
    }
    firstRun = false
  })
}

export const autoLoad = (store: any, key: string): void => {
  if (!store || !key) return

  const data = getFromStorage(key)

  if (data && store) set(store, { [key]: data })
}

export const saveToStorage = (key: string, value: any): void => {
  if (!key || !value) return
  _storage.setItem(key, JSON.stringify(value))
}

export const getFromStorage = (key: string): string | undefined => {
  const value = _storage.getItem(key)
  if (value) return JSON.parse(value)
  return
}

export const removeFromStorage = (key: string): void => {
  if (!key) return

  _storage.removeItem(key)
}

export const clearLocalStorage = (): void => {
  _storage.clear()
}

export const clearSessionStorage = (): void => {
  window.sessionStorage.clear()
}

export const clearAllStorage = (): void => {
  clearLocalStorage()
  clearSessionStorage()
}

export default {
  autoLoad,
  autoSave,
  saveToStorage,
  getFromStorage,
  removeFromStorage,
  clearLocalStorage,
  clearSessionStorage,
  clearAllStorage,
}
