import { KEYS, LABELS } from '@constants'
import {
  AcCompanyDetailAppointment,
  AcCompanyDetailDirectors,
  AcCompanyDetailWomenChartComponent,
  AcNoReporting,
} from '@components'

export interface IAcDataExplorerCompanySupervisoryTab {
  hasReported: boolean
  reasonMissing?: string
}

export const AcDataExplorerCompanySupervisoryTab = ({
  hasReported,
  reasonMissing,
}: IAcDataExplorerCompanySupervisoryTab) => {
  if (!hasReported)
    return (
      <AcNoReporting
        type={`de ${LABELS.SUPERVISORY}`}
        reasonMissing={reasonMissing}
      />
    )

  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={KEYS.SUPERVISORY}
        title="Percentage vrouwen in de RvC"
        subTitle={`Percentage vrouwen in de RvC, einde boekjaar ${LABELS.CURRENT_YEAR}`}
      />
      <AcCompanyDetailDirectors
        title="Man-vrouwverhouding in de RvC"
        subTitle={`Aantal commissarissen / niet-uitvoerende bestuurders, einde boekjaar ${LABELS.CURRENT_YEAR}`}
        subset={KEYS.SUPERVISORY}
        seats="Aantal zetels"
      />
      <AcCompanyDetailAppointment
        subset={KEYS.SUPERVISORY}
        title="Benoemingen en herbenoemingen"
        subTitle={`Percentage vrouwen bij benoemingen, 
          herbenoemingen en aftredingen in de RvC, ${LABELS.CURRENT_YEAR}`}
      />
    </>
  )
}
