import { AcHero, AcIndexFilter, AcLayoutWrapper } from '@components'
import { Outlet } from 'react-router-dom'

export const AcDaexHomeLayout = () => {
  return (
    <AcLayoutWrapper
      aboveMainChildren={
        <>
          <AcHero />
          <AcIndexFilter />
        </>
      }>
      <Outlet />
    </AcLayoutWrapper>
  )
}
