import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useStore } from '@hooks/ac-use-store'

import { DAEX_META, ROUTE_PATHS } from '@constants'
import { AcHelmet } from '@components'
// eslint-disable-next-line max-len
import { DataExplorerCompanyParent } from '@partials/tabs/data-explorer-company/data-explorer-company-parent'

export const AcDataExplorerCompany = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { analytics } = useStore()
  const { slug } = useParams()

  const title = useMemo(() => {
    return DAEX_META.COMPANY.TITLE.replace(
      '{{company}}',
      analytics.company?.name || 'Bedrijf'
    )
  }, [analytics.company?.name])

  useEffect(() => {
    const fromTabClick = (location?.state as any)?.fromTabClick
    if (slug) {
      analytics.getBySlug(slug, fromTabClick).catch(error => {
        if (error?.response?.status === 404) navigate(ROUTE_PATHS.NOT_FOUND)
      })
    }
  }, [slug])

  return (
    <>
      <AcHelmet
        title={title}
        description={DAEX_META.COMPANY.DESCRIPTION}
      />
      <DataExplorerCompanyParent />
    </>
  )
})
