import { useMemo } from 'react'

import { AcCardWithTitle, AcDataBlock } from '@components'
import {
  IAnalyticsCompanyDepartmentsLowercase,
  IAnalyticsIndexAppointmentsType,
} from '@typings'

interface IAppointmentCardProps {
  title: string
  subtitle?: string
  seriesData: {
    department: IAnalyticsCompanyDepartmentsLowercase
    metrics: {
      type: IAnalyticsIndexAppointmentsType
      value: number
      percentage: Nullable<number>
      total: Nullable<number>
    }[]
    total: number
  }[]
}

export const AcAppointmentCard = ({
  title,
  subtitle,
  seriesData,
}: IAppointmentCardProps) => {
  const renderContainers = useMemo(() => {
    return seriesData.map(({ total, department, metrics }, i) => {
      return (
        <AcDataBlock
          key={i}
          companies={total}
          department={department}
          items={metrics}
        />
      )
    })
  }, [seriesData])
  return (
    <AcCardWithTitle
      title={title}
      subTitle={subtitle}>
      {renderContainers}
    </AcCardWithTitle>
  )
}
