import {
  AcAppHeader,
  AcFooterDaex,
} from '../../features/index.features.components'
import '@styles/global.styles.scss'
import styles from './ac-layout-wrapper.module.scss'
import { useMemo } from 'react'
import clsx from 'clsx'

interface IAcLayoutWrapper {
  withAppHeader?: boolean
  withFooter?: boolean
  background?: 'white'
  children: React.ReactNode
  aboveMainChildren?: React.ReactNode
}

export const AcLayoutWrapper = ({
  withAppHeader = true,
  withFooter = true,
  background,
  aboveMainChildren,
  children,
}: IAcLayoutWrapper) => {
  const getWrapperClasses = useMemo(
    () =>
      clsx(
        styles['ac-layout-wrapper'],
        background && styles[`ac-layout-wrapper--${background}`]
      ),
    [background]
  )

  return (
    <div className={getWrapperClasses}>
      {withAppHeader && <AcAppHeader />}
      {aboveMainChildren && aboveMainChildren}
      <main className={styles['ac-layout-wrapper-main']}>{children}</main>
      {withFooter && <AcFooterDaex />}
    </div>
  )
}
