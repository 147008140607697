import { useMemo } from 'react'

import {
  DAEX_ICON_MAP,
  DYNAMIC_LABELS,
  KEYS,
  LABELS,
  TOOLTIPS,
} from '@constants'
import { AcTitle, AcTypography } from '../../core/index.core.components'
import { AcDataItem } from '../ac-data-item/ac-data-item'

import { AcIntlFloat } from '@helpers/ac-intl-number.helper'
import { IAnalyticsCompanyDepartments } from '@typings'
import styles from './ac-data-block.module.scss'

interface IDataBlockItem {
  type: 'appointment' | 'reappointment' | 'resign' | 'influx' | 'outflow'
  percentage: Nullable<number>
  value?: Nullable<number>
  total: Nullable<number>
}

const getTitle = (
  percentage: Nullable<number>,
  total: IDataBlockItem['total']
) => {
  if (percentage === 0 && total === 0) return '-'
  const percentageString = AcIntlFloat(percentage, 1)
  return percentageString ? percentageString + '%' : '-'
}

const getSubtitle = (
  type: IDataBlockItem['type'],
  total: IDataBlockItem['total']
) => {
  if (!total && total !== 0) return LABELS.NO_DATA_AVAILABLE

  const totalString = AcIntlFloat(total)

  switch (type) {
    case 'appointment':
      return `${totalString} ${LABELS.APPOINTMENT}`
    case 'reappointment':
      return `${totalString} ${LABELS.REAPPOINTMENT}`
    case 'resign':
      return `${totalString} ${LABELS.RESIGN}`
    case 'influx':
      return `${totalString} ${LABELS.INFLUX}`
    case 'outflow':
      return `${totalString} ${LABELS.OUTFLOW}`
  }
}

const getTooltip = (type: string, department: string) => {
  const DEPARTMENT = department?.toUpperCase()
  const TYPE = type?.toUpperCase()

  return (TOOLTIPS.DAEX_INDEX.TOP_SUBTOP as any)?.[DEPARTMENT]?.[TYPE]
}

export const AcDataBlock = ({
  department,
  companies,
  items,
}: {
  department: string
  items: IDataBlockItem[]
  companies?: number
}) => {
  const colorVar = useMemo(() => {
    switch (department.toLowerCase()) {
      case KEYS.DIRECTORS.toLowerCase():
        return '--ac-color-secondary-orange'
      case KEYS.SUBTOP.toLowerCase():
        return '--ac-color-secondary-lightblue2'
      case KEYS.SUPERVISORY.toLowerCase():
        return '--ac-color-secondary-green'
      case KEYS.ORGANISATION.toLowerCase():
        return '--ac-color-secondary-fuchia'
      default: {
        return '--ac-color-secondary-green'
      }
    }
  }, [department])

  const departmentString = (LABELS as any)[
    department?.toUpperCase() as IAnalyticsCompanyDepartments
  ]

  return (
    <div
      className={styles['ac-data-block']}
      style={{ '--color': `var(${colorVar})` } as React.CSSProperties}>
      <div className={styles['ac-data-block-header']}>
        <AcTitle
          className={styles['ac-data-block-header-title']}
          level={4}
          style={{ color: `var(${colorVar})` }}>
          {departmentString}
        </AcTitle>
        {companies && (
          <AcTypography
            className={styles['ac-data-block-header-title-sub']}
            element="span"
            weight="light">
            {DYNAMIC_LABELS.N_COMPANIES(companies)}
          </AcTypography>
        )}
      </div>
      <div className={styles['ac-data-block-items']}>
        {items && (
          <div className={styles['ac-data-block-items-inner']}>
            {items.map(({ type, total, percentage }) => (
              <AcDataItem
                direction="horizontal"
                iconColor={colorVar}
                key={type}
                icon={DAEX_ICON_MAP[type]}
                title={getTitle(percentage, total)}
                label={getSubtitle(type, total)}
                toolTip={getTooltip(type, department)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
