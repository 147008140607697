import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BREAKPOINTS, LABELS, ROUTE_PATHS } from '@constants'
import { AcButton, AcTextInput } from '@components'
import { handleKeyDown } from '@helpers/handle-key-down.helper'

import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import styles from './ac-search-input.module.scss'

interface IAcSearchInput {
  initialValue?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const AcSearchInput = ({ initialValue, onChange }: IAcSearchInput) => {
  const [value, setValue] = useState(initialValue ?? '')
  const navigate = useNavigate()
  const isMdAndUp = AcUseBreakpoint(BREAKPOINTS.SM)

  const submitSearch = () => {
    navigate({ pathname: ROUTE_PATHS.SEARCH, search: `?q=${value}` })
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const renderEndAdornment = useMemo(() => {
    return (
      <AcButton
        label="Zoek"
        variant={isMdAndUp ? 'pill' : 'icon'}
        icon={isMdAndUp ? void 0 : 'MAGNIFIER'}
        color="primary"
        onClick={submitSearch}
      />
    )
  }, [isMdAndUp, value])

  useEffect(() => {
    setValue(initialValue ?? '')
  }, [initialValue])

  return (
    <AcTextInput
      id="test"
      name="Test"
      placeholder={LABELS.SEARCH_COMPANIES_BY_NAME_OR_KVK}
      className={styles['ac-search-input']}
      onSubmit={submitSearch}
      onKeyDown={(e: React.KeyboardEvent) => handleKeyDown(e, submitSearch)}
      onInput={handleInput}
      value={value}
      endAdornment={renderEndAdornment}
      maxLength={36}
    />
  )
}
