import { DAEX_LISTS, LABELS } from '@constants'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef, useState } from 'react'

import { KEYS } from '@constants'
import {
  AcAnalyticsCompanyHeading,
  AcCard,
  AcCardGrid,
  AcDivider,
  AcSelectChips,
  AcShimmer,
} from '@components'
import { useStore } from '@hooks/ac-use-store'

import { IAcSingleTab } from 'src/components/core/ac-tabs/ac-tabs-header'
import styles from './data-explorer-company-parent.module.scss'
// eslint-disable-next-line max-len
import { AcDataExplorerCompanyDirectorsTab } from './directors/ac-data-explorer-company-directors-tab'

// eslint-disable-next-line max-len
import { AcDataExplorerCompanyOrganisationTab } from './organisation/ac-data-explorer-company-organisation-tab'
import { AcDataExplorerCompanySubtopTab } from './subtop/ac-data-explorer-company-subtop-tab'

// eslint-disable-next-line max-len
import { AcDataExplorerCompanyApproachTab } from './approach/data-explorer-company-approach-tab'

// eslint-disable-next-line max-len
import { AcDataExplorerCompanySupervisoryTab } from './supervisory/ac-data-explorer-company-supervisory-tab'
import ReportNotAvailable from '@assets/visuals/report-not-available.png'
import ReportExtendedTaxYear from '@assets/visuals/report-extended-tax-year.png'

export const DataExplorerCompanyParent = observer(() => {
  const { analytics } = useStore()
  const containerRef = useRef(null)
  const [selectedTab, setSelectedTab] = useState<
    Omit<IAcSingleTab, 'disabled'>
  >(DAEX_LISTS.COMPANY_DETAIL.TABS[0])

  const { loading, company } = analytics
  const { status, availableManagementBodies, targets } = company

  const handleTabChange = (tab: IAcSingleTab) => {
    if (tab.key !== KEYS.ORGANISATION) {
      setSelectedTab(tab)
    } else {
      setSelectedTab(tab)
    }
  }

  useEffect(() => {
    setSelectedTab(DAEX_LISTS.COMPANY_DETAIL.TABS[0])
  }, [loading])

  const selectChipsOptions = useMemo(() => {
    return DAEX_LISTS.COMPANY_DETAIL.TABS.map(item => {
      const alwaysVisible = {
        ...item,
        hasData: true,
      }
      if (!availableManagementBodies) return alwaysVisible
      if (item.key === 'organisation' || item.key === 'plan')
        return alwaysVisible
      return {
        ...item,
        hasData: availableManagementBodies[item.key],
      }
    })
  }, [availableManagementBodies])

  return (
    <AcCardGrid ref={containerRef}>
      <AcCard pb={1}>
        {loading && <AcShimmer />}
        <div className={styles['ac-company-detail-item-wrapper']}>
          {!loading && <AcAnalyticsCompanyHeading {...company} />}
        </div>
        {!loading && status === 'available' && (
          <>
            <AcDivider />
            <AcSelectChips
              labelKey="label"
              options={selectChipsOptions}
              value={selectedTab}
              onChange={selected => handleTabChange(selected)}
            />
          </>
        )}
      </AcCard>
      {!loading && status === 'available' && (
        <>
          {selectedTab.key === KEYS.ORGANISATION && (
            <AcDataExplorerCompanyOrganisationTab />
          )}
          {selectedTab.key === KEYS.DIRECTORS && (
            <AcDataExplorerCompanyDirectorsTab
              hasReported={availableManagementBodies[KEYS.DIRECTORS]}
              reasonMissing={targets[KEYS.DIRECTORS]?.reason_missing}
            />
          )}
          {selectedTab.key === KEYS.SUPERVISORY && (
            <AcDataExplorerCompanySupervisoryTab
              hasReported={availableManagementBodies[KEYS.SUPERVISORY]}
              reasonMissing={targets[KEYS.SUPERVISORY]?.reason_missing}
            />
          )}
          {selectedTab.key === KEYS.SUBTOP && (
            <AcDataExplorerCompanySubtopTab
              hasReported={availableManagementBodies[KEYS.SUBTOP]}
              reasonMissing={targets[KEYS.SUBTOP]?.reason_missing}
            />
          )}
          {selectedTab.key === KEYS.PLAN && (
            <AcDataExplorerCompanyApproachTab />
          )}
        </>
      )}
      {!loading && (
        <>
          {status === 'not-available' && (
            <AcCard pb={1}>
              <div className={styles['ac-company-detail-error']}>
                <img
                  src={ReportNotAvailable}
                  alt={LABELS.REPORT_NOT_AVAILABLE}
                  width={192}
                  height={172}
                />
                <p>
                  {LABELS.REPORT_NOT_AVAILABLE} {LABELS.CURRENT_YEAR}.
                </p>
              </div>
            </AcCard>
          )}
          {status === 'extended-tax-year' && (
            <AcCard pb={1}>
              <div className={styles['ac-company-detail-error']}>
                <img
                  src={ReportExtendedTaxYear}
                  alt={LABELS.REPORT_EXTENDED_YEAR}
                  width={197}
                  height={172}
                />
                <p>{LABELS.REPORT_EXTENDED_YEAR}</p>
              </div>
            </AcCard>
          )}
        </>
      )}
    </AcCardGrid>
  )
})
