import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { AcIsObjectEqual } from '@helpers/ac-is-object-equal.helper'
import { removeItemFromArray } from '@helpers/ac-remove-from-array'

import { AcButton } from '../ac-button/ac-button'
import { AcTypography } from '../ac-typography/ac-typography'

import styles from './ac-select-chips.module.scss'

interface IAcSelectChipOption {
  [key: string]: any
}

interface IAcSelectChipProps {
  value: IAcSelectChipOption
  options: readonly IAcSelectChipOption[]
  labelKey: string
  /** @default 'id' */
  valueKey?: string
  onChange?: (value: any) => void
  readOnly?: boolean
  singleSelect?: boolean
}

export const AcSelectChips = ({
  value,
  options = [],
  onChange,
  labelKey,
  valueKey = 'slug',
  readOnly,
  singleSelect,
}: IAcSelectChipProps) => {
  const handleClick = (option: IAcSelectChipOption) => {
    if (!AcIsObjectEqual(value, option)) onChange && onChange(option)
  }
  // const [value, setValue] = useState(defaultValue)

  // const toggleChip = (valueChip: readonly any[]) => {
  //   let newValue = [...value]

  //   if (singleSelect) {
  //     newValue = [valueChip]
  //   } else if (value.includes(valueChip)) {
  //     newValue = removeItemFromArray(newValue, valueChip)
  //   } else {
  //     newValue.push(valueChip)
  //   }

  //   // setValue(newValue)
  //   onChange?.(newValue)
  // }

  // const renderButtonChips = useMemo(() => {
  //   if (!options?.length) return null

  //   if (readOnly) {
  //     if (!value?.length) return null

  //     return (
  //       <AcTypography
  //         weight="semibold"
  //         size="sm">
  //         {value.map(({ [labelKey]: label }) => label).join(', ')}
  //       </AcTypography>
  //     )
  //   }

  //   const chipElements = []
  //   for (const optionChip of options) {
  //     const valueChip = value?.find(
  //       valueChip => valueChip[valueKey] === optionChip[valueKey]
  //     )
  //     const isChecked = !!valueChip
  //     chipElements.push(
  //       <AcButton
  //         key={optionChip[valueKey]}
  //         label={optionChip[labelKey]}
  //         color={isChecked ? 'primary' : 'secondary'}
  //         padding="chip"
  //         variant="pill"
  //         onClick={() => toggleChip(valueChip || optionChip)}
  //       />
  //     )
  //   }

  //   return chipElements
  // }, [value, options, readOnly])

  // useEffect(() => {
  //   if (!AcIsObjectEqual(value, defaultValue)) setValue(defaultValue)
  // }, [defaultValue])

  const renderOptions = useMemo(() => {
    return options.map(singleOption => {
      const isActive = value[valueKey] === singleOption[valueKey]

      return (
        <AcButton
          label={singleOption[labelKey]}
          key={singleOption[valueKey]}
          padding="chip"
          variant="pill"
          color={isActive ? 'primary' : 'secondary'}
          onClick={() => handleClick(singleOption)}
        />
      )
    })
  }, [options, value])

  return (
    <div
      className={clsx(
        styles['ac-select-chip'],
        readOnly && styles['ac-select-chip--read-only']
      )}>
      {renderOptions}
      {/* {renderButtonChips} */}
    </div>
  )
}
