import clsx from 'clsx'

import { AcCardWithTitle, AcChartLineFemales } from '@components'
import { IAnalyticsIndexFemales } from '@typings'

import styles from './ac-card-line-females.module.scss'

interface IAcCardLineFemales {
  title: string
  subtitle: string
  seriesData: IAnalyticsIndexFemales
  visible?: boolean
}

export const AcCardLineFemales = ({
  title,
  subtitle,
  seriesData,
  visible = true,
}: IAcCardLineFemales) => {
  return (
    <div
      className={clsx(
        styles['ac-card-line-females'],
        visible && styles['ac-card-line-females--visible']
      )}>
      <AcCardWithTitle
        title={title}
        subTitle={subtitle}>
        <div className={styles['ac-card-line-females-chart-wrapper']}>
          <AcChartLineFemales
            className={styles['ac-card-line-females-chart']}
            seriesData={seriesData}
          />
        </div>
      </AcCardWithTitle>
    </div>
  )
}
