import clsx from 'clsx'
import { ButtonHTMLAttributes, forwardRef, useMemo } from 'react'

import { AcTypography } from '../ac-typography/ac-typography'
// import { ICONS } from '@constants'
import { ICONS } from '../../../constants/index.constants'
import { handleKeyDown } from '../../../utils/helpers/handle-key-down.helper'
import { AcIcon } from '../ac-icon/ac-icon'

import styles from './ac-button.module.scss'

type IHandleClick<T> = (
  event: React.MouseEvent<T> | React.KeyboardEvent<T>
) => unknown

interface IAcButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: IHandleClick<HTMLButtonElement>
  label: string
  icon?: keyof typeof ICONS | ''
  color?: 'primary' | 'secondary'
  variant?: 'default' | 'text' | 'icon' | 'push' | 'pill' | 'link'
  /** @default 'center' */
  justifyContent?: 'center' | 'flex-start'
  /** @default 'default' */
  padding?: 'xs' | 'default' | 'md' | 'chip' | 'none'
  fullWidth?: boolean
  disabled?: boolean
  // withToolTip?: boolean
  className?: string
}

export const AcButton = forwardRef<HTMLButtonElement, IAcButtonProps>(
  (
    {
      onClick,
      label,
      icon,
      color = 'primary',
      variant = 'default',
      disabled = false,
      // withToolTip = true,
      fullWidth,
      className,
      padding = 'default',
      justifyContent = 'center',
      ...props
    }: IAcButtonProps,
    ref
  ) => {
    const handleClick: IHandleClick<HTMLButtonElement> = event => {
      if (onClick && !disabled) onClick(event)
    }

    const getButtonClassNames = useMemo(
      () =>
        clsx(
          styles['ac-button'],
          styles[`ac-button-variant-${variant}`],
          styles[`ac-button-variant-${variant}-color-${color}`],
          padding && styles[`ac-button--padding-${padding}`],
          fullWidth && styles['ac-button--full-width'],
          // icon && styles[`ac-button-variant-${variant}--has-icon`],
          disabled && styles[`ac-button-variant-${variant}--disabled`],
          className && className
        ),
      [
        label /*, icon*/,
        variant,
        className,
        disabled,
        color,
        padding,
        fullWidth,
      ]
    )

    return (
      // <AcTooltip
      //   visible={withToolTip}
      //   label={label}>
      <button
        ref={ref}
        onClick={handleClick}
        onKeyDown={e => handleKeyDown(e, () => handleClick)}
        disabled={disabled}
        className={getButtonClassNames}
        type="button"
        aria-label={label}
        {...props}>
        <span
          className={styles['ac-button-inner']}
          style={{ justifyContent }}>
          {label && variant !== 'icon' && (
            <AcTypography
              element="span"
              size="sm"
              weight="semibold"
              className={styles['ac-button-label']}>
              {label}
            </AcTypography>
          )}
          {icon && (
            <AcIcon
              icon={icon}
              className={styles['ac-button-icon']}
            />
          )}
        </span>
      </button>
      // </AcTooltip>
    )
  }
)
AcButton.displayName = 'AcButton'
