export const AcIntlFloat = (value: Nullable<number>, digits?: number) => {
  if (value === null || isNaN(Number(value))) return '-'

  return new Intl.NumberFormat('nl-NL', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(value as number)
}

export const AcIntlPrice = (value: number) =>
  new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  })
    .format(value)
    .replace(/\s+/g, '')
