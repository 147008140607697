import { ICONS, LABELS } from '@constants'
import { AcTypography, AcIcon } from '../index.core.components'

import styles from './ac-button-back.module.scss'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

interface IAcButtonBack {
  className?: string
  label?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const AcButtonBack = ({
  className,
  label = LABELS.BACK,
  onClick,
}: IAcButtonBack) => {
  const navigate = useNavigate()
  const handleBackAction = onClick || (() => navigate(-1))

  return (
    <button
      className={clsx(styles['ac-button-back'], className)}
      onClick={handleBackAction}>
      <AcIcon icon={ICONS.CHEVRON_LEFT} />
      <AcTypography>{label}</AcTypography>
    </button>
  )
}
