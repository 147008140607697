// Assets
import logo from '@assets/logos/ser_logo_data-explorer.svg'
import { BREAKPOINTS, ICONS, ROUTE_PATHS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Link, NavLink, generatePath } from 'react-router-dom'
import {
  AcIcon,
  AcTooltip,
  AcTypography,
} from '../../core/index.core.components'
import styles from './ac-app-header.module.scss'

export const AcAppHeader = () => {
  const isDesktop = AcUseBreakpoint(BREAKPOINTS.MD)
  const loginEnabled = AcUseBreakpoint(BREAKPOINTS.LG)
  const [menuOpen, setMenuOpen] = useState(false)
  const loginUnavailable =
    // eslint-disable-next-line max-len
    'We zijn druk in ontwikkeling. Momenteel is inloggen op het portaal alleen beschikbaar op een laptop of computer. '

  const getLoginButtonClasses = useMemo(
    () =>
      clsx(
        styles['ac-app-header-navlink'],
        !loginEnabled && styles['ac-app-header-navlink--disabled']
      ),
    [loginEnabled]
  )

  const renderMenuLinks = useMemo(() => {
    return (
      <>
        {!isDesktop && (
          <NavLink
            to={generatePath(ROUTE_PATHS.DAEX)}
            className={styles['ac-app-header-navlink']}>
            <AcTypography>Dataverkenner 2023</AcTypography>
          </NavLink>
        )}
        <a
          className={styles['ac-app-header-navlink']}
          href="https://www.ser.nl/nl/thema/diversiteitsportaal"
          target="_blank"
          rel="noreferrer">
          <AcTypography>Diversiteitsportaal</AcTypography>
          <AcIcon
            icon={ICONS.ARROW_UP_RIGHT}
            size={11}
          />
        </a>
        <NavLink
          to={generatePath(ROUTE_PATHS.ABOUT)}
          className={styles['ac-app-header-navlink']}>
          <AcTypography>Over de dataverkenner</AcTypography>
        </NavLink>
        {loginEnabled ? (
          <a
            href={'/login'}
            className={getLoginButtonClasses}>
            <AcIcon icon={ICONS.AVATAR} />
            <AcTypography>Login</AcTypography>
          </a>
        ) : (
          <AcTooltip
            label={loginUnavailable}
            visible={isDesktop}>
            <a
              href={'/login'}
              className={getLoginButtonClasses}>
              <AcIcon icon={ICONS.AVATAR} />
              <AcTypography>Login</AcTypography>
            </a>
          </AcTooltip>
        )}
      </>
    )
  }, [isDesktop, loginEnabled])

  const menuButtonClasses = useMemo(
    () =>
      clsx(
        styles['ac-app-header-menu-button'],
        menuOpen && styles['ac-app-header-menu-button--open']
      ),
    [menuOpen]
  )

  const getMobileMenuClasses = useMemo(
    () =>
      clsx(
        styles['ac-app-header-mobile-menu'],
        menuOpen && styles['ac-app-header-mobile-menu--visible']
      ),
    [menuOpen]
  )

  const renderMenuButton = useMemo(() => {
    return (
      <div
        className={menuButtonClasses}
        onClick={() => setMenuOpen(!menuOpen)}>
        <span className={styles['ac-app-header-menu-button-line']} />
        <span className={styles['ac-app-header-menu-button-line']} />
        <span className={styles['ac-app-header-menu-button-line']} />
      </div>
    )
  }, [menuOpen])

  return (
    <>
      <nav
        className={styles['ac-app-header']}
        id="nav">
        <div className={styles['ac-app-header-left']}>
          <Link to={ROUTE_PATHS.ANALYTICS}>
            <img
              className={styles['ac-app-header-logo']}
              src={logo}
              alt="Sociaal Economische Raad"
            />
          </Link>
        </div>
        <div className={styles['ac-app-header-right']}>
          {isDesktop ? renderMenuLinks : renderMenuButton}
        </div>
      </nav>
      {!isDesktop && (
        <div className={getMobileMenuClasses}>
          {renderMenuLinks}
          {!loginEnabled && (
            <div className={styles['ac-app-header-mobile-menu-notice']}>
              <AcTypography>{loginUnavailable}</AcTypography>
            </div>
          )}
        </div>
      )}
    </>
  )
}
