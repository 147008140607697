import clsx from 'clsx'

import { ICONS } from '@constants'
import { AcIcon } from '../../core/ac-icon/ac-icon'
import { AcTitle, AcTypography } from '@components'
import { useMemo } from 'react'
import { AcTooltip } from '../../core/ac-tooltip/ac-tooltip'

import styles from './ac-data-item.module.scss'

type DataItemColorOptions =
  | '--ac-color-primary'
  | '--ac-color-secondary-fuchia'
  | '--ac-color-secondary-orange'
  | '--ac-color-secondary-green'
  | '--ac-color-secondary-lightblue2'
  | '--ac-color-neutral-header'

interface IAcDataItemProps {
  direction?: 'horizontal' | 'vertical'
  icon?: keyof typeof ICONS
  heading?: string
  title: string
  subtitle?: string
  label?: string
  toolTip?: string | string[]
  toolTipPlacement?: 'heading' | 'title' | 'subtitle' | 'label'
  iconColor?: DataItemColorOptions
  titleColor?: DataItemColorOptions
  headingColor?: DataItemColorOptions
}

const defaultColor = '--ac-color-neutral-header'

export const AcDataItem = ({
  direction = 'vertical',
  iconColor = defaultColor,
  titleColor = defaultColor,
  headingColor = defaultColor,
  icon,
  heading,
  title,
  toolTip,
  toolTipPlacement = 'label',
  label,
  subtitle,
}: IAcDataItemProps) => {
  const getWrapperClasses = useMemo(
    () =>
      clsx(
        styles['ac-data-item'],
        direction && styles[`ac-data-item--${direction}`]
      ),
    [direction, icon]
  )

  if (title.includes('%')) {
    title = title.replaceAll('.', ',')
  }

  if (!title || title === 'null%' || title === '-%') {
    title = '-'
  }

  return (
    <div className={getWrapperClasses}>
      <div
        className={styles['ac-data-item-inner']}
        style={
          {
            fill: `var(${iconColor})`,
          } as React.CSSProperties
        }>
        {icon && (
          <AcIcon
            size={72}
            icon={icon}
            className={styles['ac-data-item-inner-icon']}
          />
        )}
        <div className={styles['ac-data-item-inner--center']}>
          {heading && (
            <div className={styles['ac-data-item-inner-heading']}>
              <AcTitle
                level={4}
                className={styles['ac-data-item-inner-heading']}
                style={
                  {
                    color: `var(${headingColor})`,
                  } as React.CSSProperties
                }>
                {heading}
              </AcTitle>
              {toolTip && toolTipPlacement === 'heading' && (
                <AcTooltip label={toolTip}>
                  <AcIcon
                    className={styles['ac-data-item-inner-info']}
                    icon={ICONS.INFO}
                    size={14}
                  />
                </AcTooltip>
              )}
            </div>
          )}
          <AcTitle
            level={2}
            className={styles['ac-data-item-inner-title']}
            style={
              {
                color: `var(${titleColor})`,
              } as React.CSSProperties
            }>
            {title}
          </AcTitle>
          {subtitle && (
            <div className={styles['ac-data-item-inner-subtitle']}>
              <AcTypography
                element="span"
                weight="semibold">
                {subtitle}
              </AcTypography>
              {toolTip && toolTipPlacement === 'subtitle' && (
                <AcTooltip label={toolTip}>
                  <AcIcon
                    className={styles['ac-data-item-inner-info']}
                    icon={ICONS.INFO}
                    size={14}
                  />
                </AcTooltip>
              )}
            </div>
          )}
          <div className={styles['ac-data-item-inner-label']}>
            <AcTypography
              element="span"
              weight="light">
              {label}
            </AcTypography>
            {toolTip && toolTipPlacement === 'label' && (
              <AcTooltip label={toolTip}>
                <AcIcon
                  className={styles['ac-data-item-inner-info']}
                  icon={ICONS.INFO}
                  size={14}
                />
              </AcTooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
