import { LABELS } from '@constants'
import { IEChartTooltipParams } from '@typings'
import { AcPercentageString } from '@helpers/ac-percentage-string.helpers'
export interface IAcChartCircleFemalesMap {
  value: Nullable<number>
  percentage: Nullable<number>
  textColor: string
}

export const AcChartCircleFemalesMap = ({
  value,
  percentage,
  textColor,
}: IAcChartCircleFemalesMap) => {
  const percentageString =
    !value && !percentage ? '-' : AcPercentageString(percentage) + '%'
  const valueString = value?.toLocaleString()
  const companyPlural = value === 1 ? 'bedrijf' : 'bedrijven'

  if (!percentage) {
    // @ts-expect-error: can cast to string
    percentage = '0.0'
  }

  const AcChartCircleFemalesConfig = {
    series: [
      {
        type: 'pie',
        radius: ['100%', '125%'],
        center: ['50%', '65%'],
        startAngle: 180,
        itemStyle: {
          borderRadius: 50,
          borderColor: '#fff',
          borderWidth: 6,
        },
        label: {
          show: false,
        },
        data: [
          {
            value: percentage || 0,
            tooltip: {
              formatter: ({ value }: IEChartTooltipParams) => {
                // @ts-expect-error: can cast to string
                if (percentage === '0.0') return '-'
                return `<div style="text-align: left;">${
                  LABELS.CURRENT_YEAR
                } <br/> Vrouw: ${AcPercentageString(value)}%</div>`
              },
            },
            label: {
              show: true,
              position: 'center',
              formatter: `{h1|${percentageString}}\n{sub|\n${valueString} ${companyPlural}}`,
              rich: {
                h1: {
                  color: textColor,
                  fontSize: 32,
                  fontFamily: 'Dax Black',
                  fontWeight: 900,
                },
                sub: {
                  fontSize: 14,
                },
              },
            },
          },
          {
            value: 100 - (percentage || 0),
            tooltip: {
              formatter: ({ value }: IEChartTooltipParams) => {
                // @ts-expect-error: can cast to string
                if (value === 100 && percentage === '0.0') return '-'
                return `<div style="text-align: left;">${
                  LABELS.CURRENT_YEAR
                } <br/> Man: ${AcPercentageString(value)}%</div>`
              },
            },
          },
          {
            tooltip: { show: false },
            cursor: 'default',
            value: 100,
            itemStyle: {
              color: 'none',
              decal: {
                symbol: 'none',
              },
            },
            label: {
              show: false,
            },
          },
        ],
      },
    ],
  }

  return AcChartCircleFemalesConfig
}
