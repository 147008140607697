// Layout
import DataExplorerLayout from '@layouts/data-explorer/AppLayout'

// Views
import {
  AcDataExplorer404,
  AcDataExplorerAboutView,
  AcDataExplorerCompany,
  AcDataExplorerIndex,
  AcDataExplorerSearch,
} from '@views'

// eslint-disable-next-line max-len
import { AcDaexCompanyDetailLayout } from '@layouts/data-explorer-company-detail/ac-daex-company-detail-layout'
import { AcDaexHomeLayout } from '@layouts/data-explorer-home/ac-daex-home-layout'
// eslint-disable-next-line max-len
import { AcDataExplorerAboutLayout } from '@layouts/data-explorer-about/ac-data-explorer-about-layout'

export const createRoutes = () => {
  return [
    {
      path: '/',
      element: <DataExplorerLayout />,
      children: [
        {
          path: 'zoeken',
          element: <AcDataExplorerSearch />,
        },
      ],
    },
    {
      path: '/',
      element: <AcDaexHomeLayout />,
      children: [
        {
          index: true,
          element: <AcDataExplorerIndex />,
        },
      ],
    },
    {
      path: '/',
      element: <AcDataExplorerAboutLayout />,
      children: [
        {
          path: '/over-de-dataverkenner',
          element: <AcDataExplorerAboutView />,
        },
        {
          path: '*',
          element: <AcDataExplorer404 />,
        },
      ],
    },

    {
      path: '/bedrijven',
      element: <AcDaexCompanyDetailLayout />,
      children: [
        {
          path: ':slug',
          element: <AcDataExplorerCompany />,
        },
      ],
    },
  ]
}
