import { useMemo } from 'react'
import { AcCard } from '@components'
import { useStore } from '@hooks/ac-use-store'
import DidNotReportVisual from '@assets/visuals/did-not-report.png'
import styles from './ac-no-reporting.module.scss'

interface IAcNoReporting {
  type: string
  reasonMissing?: string
}

export const AcNoReporting = ({ type, reasonMissing }: IAcNoReporting) => {
  const { analytics } = useStore()

  if (!analytics?.company) return null

  const { is_part_of_consolidated_declaration, name } = analytics.company
  const companyName = name || 'dit bedrijf'

  const didNotReportReason = useMemo(() => {
    switch (type) {
      case 'het bestuur':
        return `${
          name || 'Dit bedrijf'
          // eslint-disable-next-line max-len
        } rapporteert niet over het bestuur omdat het bestuur uit één persoon bestaat of omdat het bestuur uitsluitend uit rechtspersonen bestaat. Een evenwichtige m/v-samenstelling van het bestuur is dan niet mogelijk.`

      case 'de RvC':
        return `${
          name || 'Dit bedrijf'
          // eslint-disable-next-line max-len
        } rapporteert niet over de RvC / niet-uitvoerende bestuurders omdat ${companyName} geen RvC / niet-uitvoerende bestuurders heeft of omdat ${companyName} één commissaris / niet-uitvoerende bestuurder heeft. Een evenwichtige m/v-samenstelling is dan niet mogelijk.`

      case 'de subtop':
        return `${
          name || 'Dit bedrijf'
          // eslint-disable-next-line max-len
        } heeft geen subtop.`

      default:
        return ''
    }
  }, [type])

  return (
    <AcCard>
      <div
        className={styles['ac-no-reporting']}
        style={{ textAlign: 'center' }}>
        <img
          src={DidNotReportVisual}
          alt="Geen rapportage beschikbaar"
          width={162}
          height={172}
        />
        {is_part_of_consolidated_declaration ? (
          <p>{didNotReportReason}</p>
        ) : (
          <p>
            {name || 'Dit bedrijf'} rapporteert niet over {type}
            <br />
            Reden {name || 'Dit bedrijf'}: "{reasonMissing}"
          </p>
        )}
      </div>
    </AcCard>
  )
}
