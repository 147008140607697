import { AcCardWithTitle, AcDataBlock } from '@components'
import { KEYS } from '@constants'
import { useStore } from '@hooks/ac-use-store'

interface IAcInfluxOutflowCard {
  title: string
  subtitle: string
}

export const AcInfluxOutflowCard = ({
  title,
  subtitle,
}: IAcInfluxOutflowCard) => {
  const { analytics } = useStore()

  return (
    <AcCardWithTitle
      title={title}
      subTitle={subtitle}>
      <AcDataBlock
        department={KEYS.SUBTOP}
        items={analytics[KEYS.INDEX_FLOW]}
      />
    </AcCardWithTitle>
  )
}
