import { useMemo } from 'react'
import { AcTypography } from '../index.core.components'

import styles from './ac-select.module.scss'

interface IAcTypography
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name: string
  label: string
  options: {
    value: any
    label: string
  }[]
}

export const AcSelect = ({
  label,
  name,
  options,
  ...properties
}: IAcTypography) => {
  const renderOptions = useMemo(() => {
    const optionElements = []

    for (const option of options) {
      optionElements.push(
        <option
          key={option.value}
          value={option.value}>
          {option.label}
        </option>
      )
    }

    return optionElements
  }, [options])
  return (
    <div className={styles['ac-select']}>
      <AcTypography
        className={styles['ac-select-label']}
        element="legend">
        {label}
      </AcTypography>
      <select
        name={name}
        title={label}
        {...properties}>
        {renderOptions}
      </select>
    </div>
  )
}
