import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart, GaugeChart, LineChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { useMemo } from 'react'

import { BREAKPOINTS, DAEX_COLORS_SECONDARY } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { IAnalyticsIndexFemales } from '@typings'
import { EChartsOption } from 'echarts'
import { AcChartLineFemalesMap } from './ac-chart-line-females-map'
import clsx from 'clsx'
import { AcPercentageString } from '@helpers/ac-percentage-string.helpers'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GaugeChart,
  BarChart,
  LineChart,
  CanvasRenderer,
])

interface IAcChartLineFemales {
  seriesData: IAnalyticsIndexFemales
  className?: string
}

const DEFAULT_OPTIONS: EChartsOption = {
  animationDelayUpdate: function (idx: number) {
    return idx * 5
  },
  textStyle: {
    fontFamily: 'Dax',
  },
  color: DAEX_COLORS_SECONDARY,
  legend: {
    icon: 'circle',
    lineStyle: {
      color: 'transparent',
    },
    textStyle: {
      fontSize: 14,
    },
  },
  tooltip: {
    trigger: 'item',
    formatter: ({ value, name, seriesName }: any) => {
      const pct = AcPercentageString(value)
      const year = name
      const type = seriesName === 'RvC' ? 'RvC' : seriesName.toLowerCase()
      return `${year}<br/> vrouwen, ${type}: ${pct}%`
    },
    textStyle: {
      color: '#241D6D',
    },
    confine: true,
  },
  grid: {
    bottom: 100,
    left: 40,
    right: 0,
  },
  xAxis: [
    {
      type: 'category',
      position: 'bottom',
      offset: 24,
      axisPointer: {
        type: 'shadow',
      },
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        interval: 0,
        color: '#37474F',
        fontSize: 14,
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
      interval: 10,
      axisLabel: {
        formatter: '{value}%',
      },
    },
  ],
}

export const AcChartLineFemales = ({
  seriesData,
  className,
}: IAcChartLineFemales) => {
  const isMdAndUp = AcUseBreakpoint(BREAKPOINTS.MD)
  const getChartClasses = useMemo(
    () => clsx(className && className),
    [className]
  )

  const renderChart = useMemo(() => {
    const { years, series } = AcChartLineFemalesMap({
      seriesData,
      showSymbol: isMdAndUp,
    })
    const options: any = { ...DEFAULT_OPTIONS }

    options.series = series
    options.xAxis[0].data = years

    if (isMdAndUp) {
      options.legend.left = undefined
      options.legend.right = 0
      options.legend.itemGap = 40
    } else {
      options.legend.left = 0
      options.legend.right = undefined
      options.legend.itemGap = 8
    }

    return (
      <ReactEChartsCore
        className={getChartClasses}
        style={{ height: '420px', width: '100%' }}
        echarts={echarts}
        option={options}
      />
    )
  }, [seriesData, isMdAndUp])

  return renderChart
}
